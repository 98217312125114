import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import 'babylonjs-loaders';
import React, {Component} from 'react';

export default class Scene extends Component {

  onResizeWindow = () => {
    if (this.engine) {
      this.engine.resize();
    }
  }

  onDragOver = (event) => {
    event.preventDefault();
  }

  createScene = () => {
    let scene = new BABYLON.Scene(this.engine);
    scene.environmentTexture = BABYLON.CubeTexture.CreateFromPrefilteredData("/assets/model/env/Studio_Specular.env", scene);
    this.camera = new BABYLON.ArcRotateCamera("Camera", Math.PI / 2, Math.PI / 2, 0, new BABYLON.Vector3(0, 30, 0), scene);
    this.camera.attachControl(this.canvas, false);
    this.canvas.oncontextmenu = function () { return false; };
    scene.activeCamera.radius += 220;
    scene.clearColor = new BABYLON.Color3.White();
    //front left light
   // let light1 = new BABYLON.DirectionalLight("DirectionalLight1", new BABYLON.Vector3(-2000, -2000, -2000), scene);
   // light1.intensity = 1.75;
    //light1.diffuse = new BABYLON.Color3(1, 0, 1); //light colour purple
    //front right light
  //  let light2 = new BABYLON.DirectionalLight("DirectionalLight2", new BABYLON.Vector3(2000, -2000, -2000), scene);
   // light2.intensity = 1.75;
    //light2.diffuse = new BABYLON.Color3(1, 0, 0); //light colour red
    //back left light
 //   let light3 = new BABYLON.DirectionalLight("DirectionalLight3", new BABYLON.Vector3(-2000, -2000, 2000), scene);
 //   light3.intensity = 1.75;
    //light3.diffuse = new BABYLON.Color3(1, 3, 0); //light colour yellow
    //back right light
 //   let light4 = new BABYLON.DirectionalLight("DirectionalLight4", new BABYLON.Vector3(2000, -2000, 2000), scene);
  //  light4.intensity = 1.75;
    //light4.diffuse = new BABYLON.Color3(0.07, 0.4, 1);  //light colour blue


    let matBB = new BABYLON.StandardMaterial("matBB", scene);
    matBB.emissiveColor = new BABYLON.Color3(1, 1, 1);
    matBB.wireframe = true;
    matBB.alpha  = 0;

    this.boxLeft = BABYLON.Mesh.CreateBox("boxleft", 20, scene);
    this.boxLeft.material = matBB;
    this.boxLeft.position = new BABYLON.Vector3(20, 10, 0);

    // OBB - Object boundind box
    this.boxMiddle = BABYLON.Mesh.CreateBox("boxmiddle", 20, scene);
    this.boxMiddle.position = new BABYLON.Vector3(0, 10, 0);
    this.boxMiddle.material = matBB;

    this.boxRight = BABYLON.Mesh.CreateBox("boxright", 20, scene);
    this.boxRight.position = new BABYLON.Vector3(-20, 10, 0);
    this.boxRight.material = matBB;

  //  this.gizmoManager.attachableMeshes = [this.boxLeft, this.boxRight, this.boxMiddle];
  /*  let pointerDragBehavior1 = new BABYLON.PointerDragBehavior({dragAxis: new BABYLON.Vector3(1,0,0)});
    this.boxRight.addBehavior(pointerDragBehavior1);
    let pointerDragBehavior2 = new BABYLON.PointerDragBehavior({dragAxis: new BABYLON.Vector3(1,0,0)});
    this.boxMiddle.addBehavior(pointerDragBehavior2);
    let pointerDragBehavior3 = new BABYLON.PointerDragBehavior({dragAxis: new BABYLON.Vector3(1,0,0)});
    this.boxLeft.addBehavior(pointerDragBehavior3);*/

    this.boxMiddle.setEnabled(false);
    this.boxRight.setEnabled(false);
    this.boxLeft.setEnabled(false);

    // show axis
    var showAxis = function(size) {
      var makeTextPlane = function(text, color, size) {
        var dynamicTexture = new BABYLON.DynamicTexture("DynamicTexture", 50, scene, true);
        dynamicTexture.hasAlpha = true;
        dynamicTexture.drawText(text, 5, 40, "bold 36px Arial", color , "transparent", true);
        var plane = new BABYLON.Mesh.CreatePlane("TextPlane", size, scene, true);
        plane.material = new BABYLON.StandardMaterial("TextPlaneMaterial", scene);
        plane.material.backFaceCulling = false;
        plane.material.specularColor = new BABYLON.Color3(0, 0, 0);
        plane.material.diffuseTexture = dynamicTexture;
        return plane;
      };

      var axisX = BABYLON.Mesh.CreateLines("axisX", [
        new BABYLON.Vector3.Zero(), new BABYLON.Vector3(size, 0, 0), new BABYLON.Vector3(size * 0.95, 0.05 * size, 0),
        new BABYLON.Vector3(size, 0, 0), new BABYLON.Vector3(size * 0.95, -0.05 * size, 0)
      ], scene);
      axisX.color = new BABYLON.Color3(1, 0, 0);
      var xChar = makeTextPlane("X", "red", size / 10);
      xChar.position = new BABYLON.Vector3(0.9 * size, -0.05 * size, 0);
      var axisY = BABYLON.Mesh.CreateLines("axisY", [
        new BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, size, 0), new BABYLON.Vector3( -0.05 * size, size * 0.95, 0),
        new BABYLON.Vector3(0, size, 0), new BABYLON.Vector3( 0.05 * size, size * 0.95, 0)
      ], scene);
      axisY.color = new BABYLON.Color3(0, 1, 0);
      var yChar = makeTextPlane("Y", "green", size / 10);
      yChar.position = new BABYLON.Vector3(0, 0.9 * size, -0.05 * size);
      var axisZ = BABYLON.Mesh.CreateLines("axisZ", [
        new BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, 0, size), new BABYLON.Vector3( 0 , -0.05 * size, size * 0.95),
        new BABYLON.Vector3(0, 0, size), new BABYLON.Vector3( 0, 0.05 * size, size * 0.95)
      ], scene);
      axisZ.color = new BABYLON.Color3(0, 0, 1);
      var zChar = makeTextPlane("Z", "blue", size / 10);
      zChar.position = new BABYLON.Vector3(0, 0.05 * size, 0.9 * size);
    };

    //showAxis(5);

    return scene;
  }




  componentDidMount () {
    this.engine = new BABYLON.Engine(
        this.canvas,
        true,
        this.props.engineOptions,
        this.props.adaptToDeviceRatio
    );

    this.engine.enableOfflineSupport﻿ = false;
    this.scene = this.createScene();

    if (typeof this.props.onSceneMount === 'function') {
      this.props.onSceneMount({
        scene: this.scene,
        engine: this.engine,
        canvas: this.canvas,
        boxLeft: this.boxLeft,
        boxRight: this.boxRight,
        boxMiddle: this.boxMiddle,
        gizmoManager: this.gizmoManager
      });
    } else {
      console.error('onSceneMount function not available');
    }

    // Resize the babylon engine when the window is resized
    window.addEventListener('resize', this.onResizeWindow);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResizeWindow);
  }

  onCanvasLoaded = (c) => {
    if (c !== null) {
      this.canvas = c;
    }
  }

  setFullScreen = (c) => {
    if (this.engine) {
      this.engine.switchFullscreen(true);
    }
  }

  zoomOut = () => {
    if (this.scene) {
      this.scene.activeCamera.radius += this.scene.activeCamera.speed*50;
    }
  }

  zoomIn = () => {
    if (this.scene) {
      this.scene.activeCamera.radius -= this.scene.activeCamera.speed*50;
    }
  }

  removeModel = (event) => {
    const tagName =  event.target.dataset.tagname
    this.props.removeModel(tagName)
  }

  capture = () => {
    this.loadingElement.style.visibility = "visible";
    if (this.scene) {
      BABYLON.Tools.CreateScreenshotUsingRenderTarget(this.engine, this.camera,
        {precision: 8, width: this.canvas.width, height: this.canvas.height});
      setTimeout(() => {
        this.loadingElement.style.visibility = "hidden";
      },3000);
    }
  }

  render () {
    // 'rest' can contain additional properties that you can flow through to canvas:
    // (id, className, etc.)
    let { width, height, ...rest } = this.props;
    let opts = {};

    return (
      <div className="babylon">
        <div className="babylon-action">
          <a onClick={this.zoomIn} title="Zoom in"><img src="images/icons/icon-zoom-in.png"></img></a>
          <a onClick={this.zoomOut} title="Zoom out"><img src="images/icons/icon-zoom-out.png"></img></a>
          <a onClick={this.setFullScreen} title="Full screen"><img src="images/icons/icon-fit-screen.png"></img></a>
          <a onClick={() => this.props.isometric()} title="Isometric"><img src="images/icons/icon-isometric.png"></img></a>
          <a onClick={this.capture} title="Capture View"><img src="images/icons/icon-camera.png"></img></a>
          <div className="babylon-action-right pull-right">
            {/*<a  onClick={() => this.props.reset()} title="Undo">
              <img src="images/icons/icon-undo.png"/>
            </a>*/}
            <a  data-toggle="modal"
              data-target="#resetView" title="Reset view">
              <img src="images/icons/icon-reset.png"/>
            </a>
        </div>
        </div>
        <div className="babylon-canvas">
          <div className="babylon-loading" id="babylon-loading" ref={ (l) => this.loadingElement = l}>
            <div className="babylon-loading-child">
              <img className="babylon-loading-img fa-spin" src="/images/loading.svg"/>
            </div>
          </div>
          <canvas
            touch-action="none"
            onDragOver={this.onDragOver}
            onDrop={(e) => this.props.onDropElement(e)}
            style={{touchAction: 'none', width: '100%', height: '100%'}}
            {...opts}
            ref={this.onCanvasLoaded}/>
        </div>

        <div className="modal fade dapp-white" id="resetView" tabIndex="-1" role="dialog"
             aria-labelledby="installModalLabel" data-backdrop="static" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-body text-center mt-3">
                  <div style={{fontSize: 16, fontWeight: 'bold'}}>ARE YOU SURE</div>
                  <div style={{color: '#666', marginTop: 5}}>All your change will be lost</div>
                  <div className="justify-content-center footer-button">
                    <button className="btn btn-danger btn btn-block mb-3" data-dismiss="modal">NO</button>
                    <button className="btn btn-primary btn btn-block btn-success mb-3" data-dismiss="modal" onClick={() => this.props.reset()}>YES</button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
