import React, {Component} from 'react';
import Header from "../../components/Header";
import {fetchItems, submitFilter, submitModels, quoteActions} from "../../actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import $ from "jquery";

class GetQuote extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'youritem',
      quoteNote: '',
      isAdd: false,
      notes: '',
      estDate: '',
      cost: '',
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitInstall = this.handleSubmitInstall.bind(this);
    this.removeNotes = this.removeNotes.bind(this);
    this.emailQuote = this.emailQuote.bind(this);
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]: value});
  }

  handleSubmit(e) {
    e.preventDefault();
    const {quoteNote} = this.state;
    if (quoteNote) {
      console.log(quoteNote);
      //this.props.updateQuote(quoteNote);
    }
  }

  handleSubmitInstall(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
      isAdd: true,
    });
    $('#installModal').modal('hide');
  }

  removeNotes(){
    this.setState({
      notes: '',
      estDate: '',
      cost: '',
      isAdd: false,
    })
  }

  emailQuote() {
    const {notes, estDate, cost, quoteNote} = this.state;
    const {models = [], screenshots, customColors, dimension} = this.props.mainpage;
    let {user} = this.props;
    if (typeof user === 'string') {
      user = JSON.parse(user);
    }
    const userId = user ? user.id : null;
    console.log("this.total", this.total)
    this.props.emailQuote({
      install: { notes, estDate, cost},
      models,
      quoteNote,
      screenshots,
      customColors,
      userId,
      dimension,
      total: this.total
    });
    this.props.history.push("/sendquote");
  }

  render() {
    const {models, customColors} = this.props.mainpage;
    const {quoteNote, isAdd, notes, estDate, cost, submitted} = this.state;
    const name = (models && models[0]) ? models[0].name || '' : '';
    let w = 0, d = 0,  h = 0 , total = 0.00;
    const colorId = customColors && customColors['CABINET'] ? customColors['CABINET'].id : 3
    console.log("colorId", colorId)
    if(models) {
      models.forEach( model => {
        w += parseInt(model.width || 0)
        d = d < parseInt(model.depth || 0) ? parseInt(model.depth) : d
        h = h < parseInt(model.height || 0) ? parseInt(model.height) : h
        model.modules.filter(m => m.colorId === colorId || !m.colorId).forEach(module => {
          total += parseFloat(module.price)
        })
      })
    }
    this.total = total
    this.props.mainpage.dimension = {
      "w": w, 
      "d": d,
      "h": h
    };
    return (
      <div>
        <Header/>
        <div className="getquote">
          <div className="row">
            <div className="col-lg-9">
              <div className="filter p-5">
                <div className="filter-page-tab">
                  <div className="row">
                    <div className="col-lg-8 text-uppercase">
                      <div className={this.state.activeTab === 'youritem' ? 'filter-tabs' : 'disabled-tab filter-tabs'}
                           onClick={() => this.setState({activeTab: 'youritem'})}>
                        Your items
                      </div>
                    </div>
                  </div>
                </div>

                {
                  this.state.activeTab === 'youritem' &&
                  (<div className="filter-youritem">
                    <ul className="quote-list">
                      <li className="quote-header">
                        <div className="quote-content d-flex justify-content-between align-items-center">
                          <div className="col-lg-2 p-2">
                            AlfrescoPlus Code
                          </div>
                          <div className="col-lg-2 p-2">
                            Bunnings<br/>Item No.
                          </div>
                          <div className="col-lg-5 p-2">
                            Description
                          </div>
                          <div className="col-lg-2 p-2">
                            Cabinet<br/>Colour
                          </div>
                          <div className="col-lg-1 quote-price p-2 ml-auto">
                            Price
                          </div>
                        </div>
                      </li>
                      {models && models.slice(0, -1).filter(model => model.modules.length).map(model => (
                        <li key={model.id} className="quote-item">
                          <div className="quote-title d-flex justify-content-between align-items-center"></div>
                          { model.modules.filter(m => m.colorId === colorId || !m.colorId).map( module => (
                            <div  key={module.id} className="quote-content d-flex justify-content-between align-items-center">
                              <div className="col-lg-2 p-2">
                                {module.sitroCode}
                              </div>
                              <div className="col-lg-2 p-2">
                                {module.bunningsCode}
                              </div>
                              <div className="col-lg-5 p-2">
                                {module.desc}
                              </div>
                              <div className="col-lg-2 p-2">
                                {module.cabinet ? (customColors['CABINET'] ? customColors['CABINET'].name : 'Artic White') : ''}
                              </div>
                              <div className="col-lg-1 quote-price p-2 ml-auto">
                                ${model.name === 'End Panel' ? (Math.round(((module.price || 0) * 2) * 100) / 100).toFixed(2) : module.price}
                              </div>
                            </div>
                          ))}
                        </li>
                      ))}
                    </ul>

                    <div className="install-delivery" style={{display: 'none'}}>
                      <ul className="quote-list">
                        <li className="quote-item">
                          <div className="quote-title mb-4 d-flex flex-row">
                            <div className="text-left">
                              Installation and Delivery
                            </div>
                            {isAdd && (
                              <div className="text-right install-button">
                                <a className='' data-toggle="modal"
                                   data-target="#installModal"><i className="far fa-edit"></i></a>
                                <a className='ml-1' onClick={this.removeNotes}><i className="far fa-trash-alt"></i></a>
                              </div>
                            )}

                          </div>
                          {isAdd
                            ? (
                              <div className="quote-content d-flex justify-content-between align-items-center">
                                <div className="p-2">
                                  {notes}
                                </div>
                                <div className="quote-summary p-2">
                                  {estDate}
                                </div>
                                <div className="quote-price p-2 ml-auto">
                                  ${cost}
                                </div>
                              </div>
                            ) : (
                              <div className="quote-content d-flex justify-content-between align-items-center">
                                <div className="quote-img">
                                  <a data-toggle="modal"
                                     data-target="#installModal"
                                     className='btn btn-black-border text-uppercase mw-200'>Add</a>
                                </div>
                                <div className="quote-price p-2 ml-auto">
                                  0$
                                </div>
                              </div>
                            )}

                        </li>
                      </ul>
                      <div className="modal fade dapp-white" id="installModal" tabIndex="-1" role="dialog"
                           aria-labelledby="installModalLabel" data-backdrop="static" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h3 className="modal-title">Install and Delivery</h3>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="install-list">
                                <form method="POST">
                                  <div className="form-group" id="errorLogin">
                                  </div>
                                  <div className={'form-group' + (submitted && !notes ? ' has-error' : '')}>
                                    <div className="row">
                                      <div className="col-sm-4">
                                        <label htmlFor="username">Installation Notes</label>
                                      </div>
                                      <div className="col-sm-8">
                                        <textarea className="form-control" name="notes" value={notes} rows="3" onChange={this.handleChange} />
                                        {submitted && !notes &&
                                        <div className="help-block">Please enter Installation Notes</div>
                                        }
                                      </div>
                                    </div>

                                  </div>
                                  <div className={'form-group' + (submitted && !estDate ? ' has-error' : '')}>
                                    <div className="row">
                                      <div className="col-sm-4">
                                        <label htmlFor="password">Est. Ship/Install date</label>
                                      </div>
                                      <div className="col-sm-8">
                                        <input type="date" className="form-control" name="estDate" value={estDate} onChange={this.handleChange} />
                                        {submitted && !estDate &&
                                        <div className="help-block">Please enter Est. Ship/Install date</div>
                                        }
                                      </div>
                                    </div>

                                  </div>
                                  <div className={'form-group' + (submitted && !cost ? ' has-error' : '')}>
                                    <div className="row">
                                      <div className="col-sm-4">
                                        <label htmlFor="password">Cost</label>
                                      </div>
                                      <div className="col-sm-8">
                                        <input type="number" className="form-control" name="cost" value={cost} onChange={this.handleChange} />
                                        {submitted && !estDate &&
                                        <div className="help-block">Please enter cost</div>
                                        }
                                      </div>
                                    </div>

                                  </div>
                                  <div className="form-group mt-3">
                                    <div className="justify-content-center footer-button">
                                      <button className="btn btn-secondary btn btn-block mb-3" data-dismiss="modal">Go back</button>
                                      <button className="btn btn-primary btn btn-block btn-success mb-3" onClick={this.handleSubmitInstall}>Confirm</button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>)
                }

                {
                  this.state.activeTab === 'products' &&
                  (<div className="quote-product py-4">
                    <div className="quote-product-info">
                      <p><strong>QUOTE NOTES</strong></p>
                      <form>
                        <textarea type="text" className="form-control" name="quoteNote" value={quoteNote}
                                  onChange={this.handleChange} rows="3"></textarea>
                        <p className="mt-2 text-right">
                          <button className="btn text-right btn-secondary mb-3" onClick={this.handleSubmit}>UPDATE
                          </button>
                        </p>

                      </form>
                    </div>
                    <div className="quote-product-info mt-3">
                      <p className="text-uppercase"><strong>Colour and Material Information</strong></p>
                      {models && models.map(model => (
                        <p key={model.id}>
                          <div className="quote-title no-border">{model.name}</div>
                        </p>
                      ))}

                    </div>
                    <div className="quote-product-inner mt-3">
                      <p><strong>EXCLUSIONS</strong></p>
                      <p>This quote does not include any costs or additional costs assosciated with connecting or
                        moving or services (eg. Gas, electricity, etc.). It also excludes site preparation & assumes
                        that the alfresco area has been cleared and cleaned, and is ready for installation.</p>
                      <p><strong>PAYMENT TERMS & METHODS</strong></p>
                      <p>A 50% deposit is required with new orders, with 40% due the day before delivery & the
                        remaining 10% balance on completion. Payment can be made via credit card (MasterCard or
                        Visa) by calling 1300 794 839 or bank deposit to: Name: Outdoors Domain | Bank: ANZ | BSB:
                        013366 | ACC: 836292867</p>
                      <p><strong>COOLING-OFF PERIOD & CANCELLATION</strong></p>
                      <p>Upon signing this quote, you have a 48 hour cooling off period. During this time you can
                        cancel your order at any time without reason. Should you decide to cancel your Cabinex™
                        Kitchen order after this time, you will be charged a restocking fee of 25% of your total
                        kitchen price as agreed on. Any amount paid over this value will be refunded and work on
                        your kitchen will cease.</p>
                    </div>
                  </div>)
                }
              </div>
            </div>
            <div className="col-lg-3 quote-email p-5">
              <div
                className="quote-email-content m-auto text-center d-flex flex-column justify-content-center justify-self-center">
                <div>
                  <img src="/images/warning-icon.png" alt=""/>
                  <div className="mt-3">
                    <p>Consider surrounding permanent fixtures such as Balustrades & Pillars.</p>
                    <p>Please check your connections.</p>
                    <p>These include Water + Waste, Plumbing, Electrical & Gas Points.</p>
                  </div>
                </div>
                <div className="quote-email-info mt-5">
                  <p>Total Dimensions</p>
                  <p>Width: {w}mm</p>
                  <p>Depth: {d}mm</p>
                  <p>Height: {h}mm</p>
                </div>
                <div className="quote-email-price font-bold  mt-4">
                  Total Price: ${models && (Math.round((total || 0) * 100) / 100).toFixed(2)}
                </div>
                <div className="quote-email-action mt-4">
                  <p><a onClick={this.emailQuote}
                        className="btn btn-success text-uppercase text-white">Send estimate</a></p>
                  <p><a onClick={() => this.props.history.push("/main")}
                        className="btn btn-black-border text-uppercase">Go back</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

function mapStateToProps(state) {
  const {filter, mainpage, authentication: { user = {}}} = state;
  return {
    filter, mainpage, user,
  };
}

const actionCreators = {
  emailQuote: quoteActions.emailQuote,
  updateQuote: quoteActions.updateQuote,
};

const connectedGetQuotePage = connect(mapStateToProps, actionCreators)(withRouter(GetQuote));
export { connectedGetQuotePage as GetQuote };

