export function priceFormat(value) {
  // Create our number formatter.
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    // the default value for minimumFractionDigits depends on the currency
    // and is usually already 2
  });

  return formatter.format(value);
}

export async function checkExistAsset(asset_url){
  const res = await fetch(asset_url);
  const text = await res.text();
  try {
    JSON.parse(text);
    return true;
  } catch (e) {
    return false;
  }
}
