import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {userActions} from "../actions";
import { connect } from 'react-redux';

const LogoImage = styled.img`
  height: 24px;
`;

class Header extends Component {

  render() {
    return (
      <nav className="navbar navbar-dark bg-white navbar-expand-lg header">
        <div className="container-fluid">
          <Link to="#" className="navbar-brand">
            <LogoImage src="/images/logo.png" alt="Logo" />
          </Link>
          <div className="d-none d-lg-block">
            <ul className="header-nav navbar-nav ml-auto text-right">
              {/*<li className="nav-item">
                <Link className="nav-link" to="/profile">
                  <i className="fas fa-user"></i>
                </Link>
              </li>*/}
              {/*<li className="nav-item">
                <Link className="nav-link" to="#">
                  <i className="fas fa-question"></i>
                </Link>
              </li>*/}

              {/*<li className="nav-item" onClick={() => this.props.onConfig && this.props.onConfig()}>
                <Link className="nav-link" to="#">
                  <i className="fas fa-cog"></i>
                </Link>
              </li>*/}
              {/*<li className="nav-item" onClick={() => this.props.logout()}>
                <Link className="nav-link" to="#">
                  <i className="fas fa-sign-out-alt"></i>
                </Link>
              </li>*/}
            </ul>
          </div>
          <button className="navbar-toggler collapsed custom-toggler" type="button" data-toggle="collapse" data-target="#sidebarContent"
                  aria-controls="sidebarContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

        </div>
      </nav>
    );
  }
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
};

export default connect(null, actionCreators)(Header);

