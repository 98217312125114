import axios from 'axios';
import {FETCH_ITEMS, FETCH_TEST, SUBMIT_FILTER, SUBMIT_MODELS, FETCH_COLORS, FETCH_PRODUCT_MENUS, FETCH_SITE_UNDER_CONSTRUCTION} from './types';

const API_ROOT = '/server';

// eslint-disable-next-line import/prefer-default-export
export function fetchTest() {
  return (dispatch) => {
    axios.get(`${API_ROOT}/test`).then((response) => {
      dispatch({
        type: FETCH_TEST,
        payload: response.data,
      });
    });
  };
}

export function submitFilter(filter) {
  return (dispatch) => {
      dispatch({
        type: SUBMIT_FILTER,
        filter,
      });
  };
}

export function submitModels(models, screenshots, customColors) {
  console.log(models)
  return (dispatch) => {
      dispatch({
        type: SUBMIT_MODELS,
        models,
        screenshots,
        customColors
      });
  };
}

export function fetchItems(filter) {
  return (dispatch) => {
    console.log(filter)
    let filterParams = {};
    if (filter.price) {
      filterParams.price = filter.price[0] + "-" + filter.price[1];
    }
    if (filter.shape) {
      filterParams.shape = filter.shape.type;
      filterParams.width = filter.shape.w;
      filterParams.depth = filter.shape.d;
    }
    axios.get(`${API_ROOT}/products`, {
      params: filterParams
    }).then((response) => {
      dispatch({
        type: FETCH_ITEMS,
        items: response.data,
      });
    });
  };
}

export function fetchColors() {
  return (dispatch) => {
    axios.get(`${API_ROOT}/colors`).then((response) => {
      dispatch({
        type: FETCH_COLORS,
        colors: response.data,
      });
    });
  };
}

export function fetchProductMenus() {
  return (dispatch) => {
    axios.get(`${API_ROOT}/productMenus`).then((response) => {
      dispatch({
        type: FETCH_PRODUCT_MENUS,
        productMenus: response.data,
      });
    });
  };
}

export function fetchSiteUnderConstruction() {
  return (dispatch) => {
    axios.get(`${API_ROOT}/siteUnderConstruction`).then((response) => {
      dispatch({
        type: FETCH_SITE_UNDER_CONSTRUCTION,
        siteUnderConstruction: response.data,
      });
    });
  };
}

export * from './alert.actions';
export * from './user.actions';
export * from './quote.actions';
