import {SUBMIT_FILTER} from '../actions/types';

const DEFAULT_STATE = {
  price: null, shape: {
    "type": "I",
    "w": null,
    "d": null,
  }
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SUBMIT_FILTER:
      return action.filter || state;
    default:
      return state;
  }
};
