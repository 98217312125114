import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../../actions';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    //this.props.logout();

    this.state = {
      username: '',
      password: '',
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { username, password } = this.state;
    if (username && password) {
      this.props.login(username, password, this.props.history);
    }
  }

  render() {
    const { loggedIn, message } = this.props;
    console.log(this.props)
    const { username, password, submitted } = this.state;

    return (
      <div className="login p-4">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-lg-4">
              <form className="login-form" method="POST">
                <div className="text-center mb-4">
                  <img src="/images/logo.svg"/>
                </div>
                <div className="form-group" id="errorLogin">
                </div>
                <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                  <label htmlFor="username">Email</label>
                  <input type="email" className="form-control" name="username" value={username} onChange={this.handleChange} required/>
                  {submitted && !username &&
                  <div className="help-block">Email is required</div>
                  }
                </div>
                <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                  <label htmlFor="password">Password</label>
                  <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} required/>
                  {submitted && !password &&
                  <div className="help-block">Password is required</div>
                  }
                  {!loggedIn && message && message != 'Registration successful' &&
                  <div className="help-block mt-1">Invalid username or password</div>
                  }
                </div>
                <div className="form-group">
                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-6 text-center">
                      <div className="login-action mt-2">
                        {/*<a href="/filter" className="btn btn-block btn-success mb-3">Login</a>*/}
                        <button className="btn btn-primary btn btn-block btn-success mb-3" onClick={this.handleSubmit}>Login</button>
                        <a href="#" className="login-link">Forgot password</a>
                      </div>
                      <div className="login-other mt-4">
                        <p className="text-uppercase font-medium text-dark">First time user?</p>
                        <Link to="/signup" className="btn btn-block login-link btn-dark mb-3">Create account</Link>
                        <Link to="/main" className="btn btn-block login-link btn-dark">Continue as guest</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="login-footer text-center">
           <img src="/images/bunnings-logo-colour.png" alt="wavehouse"/>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { loggedIn } = state.authentication;
  const { message = null } = state.alert;
  return { loggedIn, message };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(withRouter(LoginPage));
export { connectedLoginPage as LoginPage };
