import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../components/Header';
import {quoteActions, userActions} from '../actions';
import { QuoteHistoryTable } from "../components/profile/QuoteHistoryTable";
import {withRouter, Link} from "react-router-dom";


class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      term: '',
    };
  }

  componentDidMount() {
    let { user } = this.props;
    if (user) {
      if (typeof user === 'string') {
        user = JSON.parse(user);
      }
      this.props.getQuotes(user.id);
    }
  }

  render() {
    let { user, quote: { quotes = [] } } = this.props;
    let { term } = this.state;
    if (user) {
      if (typeof user === 'string') {
        user = JSON.parse(user);
      }
    }
    return (
      <div className="profile-page">
        <Header />
        <div className='column profile-wrap'>
          { user
            ? (
                <div>
                  <Link to="/main" className="exit-btn">
                    <img src="images/icons/icon-exit.png"></img>
                  </Link>
                  <div className='row row-padding'>
                    <div className='col-md-4'>
                      <p className='text' style={{fontWeight: 'bold'}}>
                        ACCOUNT DETAILS
                      </p>
                      <p className='text'>
                        Account holder: {`${user.first_name || ''} ${user.last_name || ''}`}
                      </p>
                      <p className='text'>
                        Username: {user.user_name || ''}
                      </p>
                    </div>
                    <div className='col-md-8 button-panel' >
                      <Link className='btn btn-secondary btn-gray-2' to="/login">Change Account</Link>
                      &nbsp;&nbsp;&nbsp;
                      <button className='btn btn-danger' onClick={() => this.props.logout()}>Log Out</button>
                    </div>
                  </div>
                  <div className='row row-padding'>
                    <div className='col-md-3'>
                      <div className='underline-title'>
                        QUOTE HISTORY
                      </div>
                    </div>
                    <div className='col-md-3 offset-md-6 pull-right input-group'>
                      <input type="text" className="form-control search-input" onChange={(e) => this.setState({ term: e.target.value })} placeholder="Search ..."/>
                      <div className="input-group-append search-icon">
                        <button className="btn btn-secondary btn-gray-2" type="button">
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <QuoteHistoryTable {...this.props} searchText={term} />
                  </div>
                </div>
              )
            : (
                <div className="text-center p-3 mt-5" style={{ fontSize: 16 }}>
                  <p>Please <a href="/login" style={{ padding: 0, margin: 0, color: '#70be1b', fontSize: 16 }}>Login</a> to access this page.</p>
                </div>
              )
          }

        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { quote, authentication: { user = null } } = state;

  return {
    quote,
    user
  };
}

const actionCreators = {
  getQuotes: quoteActions.getQuotes,
  getQuoteById: quoteActions.getQuoteById,
  logout: userActions.logout,
};

const connectedProfilePage = connect(mapStateToProps, actionCreators)(withRouter(Profile));
export { connectedProfilePage as Profile };
