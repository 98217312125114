import { combineReducers } from 'redux';
import test from './testReducer';
import filter from './filter';
import mainpage from './mainpage';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import {quote} from "./quote.reducer";

const rootReducer = combineReducers({
  test,
  filter,
  mainpage,
  authentication,
  registration,
  users,
  alert,
  quote
});

export default rootReducer;
