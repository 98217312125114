import { quoteConstants, userConstants,  } from '../constants';
import { UPDATE_MAIN_MODELS  } from '../actions/types';
import { quoteService } from '../services';

export const quoteActions = {
  emailQuote,
  updateQuote,
  getQuotes,
  getQuoteById,
  downloadPDF,
};

function getQuotes(userId) {
  return dispatch => {
    dispatch(request());

    quoteService.getQuotes(userId)
        .then(
            quotes => dispatch(success(quotes)),
            error => dispatch(failure(error.toString()))
        );
  };

  function request() {
    return {type: quoteConstants.GETALL_REQUEST}
  }

  function success(quotes) {
    return {type: quoteConstants.GET_QUOTES_SUCCESS, quotes}
  }

  function failure(error) {
    return {type: quoteConstants.GETALL_FAILURE, error}
  }
}

function getQuoteById(userId, quoteId) {
  return dispatch => {
    dispatch(request());

    quoteService.getQuoteById(userId, quoteId)
        .then(
            quotes => {
              dispatch(success(quotes))
              dispatch(updatemain(quotes))
            },
            error => dispatch(failure(error.toString()))
        );
  };

  function request() {
    return {type: quoteConstants.GETALL_REQUEST}
  }

  function success(quotes) {
    return {type: quoteConstants.GET_QUOTE_BY_ID_SUCCESS, quotes}
  }

  function updatemain(quotes) {
    return {type: UPDATE_MAIN_MODELS, quotes}
  }

  function failure(error) {
    return {type: quoteConstants.GETALL_FAILURE, error}
  }
}


function emailQuote(data) {
  return dispatch => {
    dispatch({
      type: quoteConstants.SUBMIT_MODEL_QUOTE,
      data
    });
  };

  function request() {
    return {type: quoteConstants.GETALL_REQUEST}
  }

  function success(users) {
    return {type: quoteConstants.GETALL_SUCCESS, users}
  }

  function failure(error) {
    return {type: quoteConstants.GETALL_FAILURE, error}
  }
}

function updateQuote() {
  return dispatch => {
    dispatch(request());

    quoteService.updateQuote()
      .then(
        users => dispatch(success(users)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request() {
    return {type: quoteConstants.GETALL_REQUEST}
  }

  function success(users) {
    return {type: quoteConstants.GETALL_SUCCESS, users}
  }

  function failure(error) {
    return {type: quoteConstants.GETALL_FAILURE, error}
  }
}

function downloadPDF(params) {
  return dispatch => {
    dispatch(request());

    quoteService.downloadPDF(params)
      .then(
        blob => {
          if (blob) {
            const a = document.createElement("a");
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = 'download.pdf';
            a.click();
            window.URL.revokeObjectURL(url);
          }
        },
        error => dispatch(failure(error.toString()))
      );
  };

  function request() {
    return {type: quoteConstants.GETALL_REQUEST}
  }

  function success(result) {
    return {type: quoteConstants.DOWNLOAD_SUCCESS, result}
  }

  function failure(error) {
    return {type: quoteConstants.GETALL_FAILURE, error}
  }
}

