import React, {Component} from 'react';
import Header from "../../components/Header";
import connect from "react-redux/es/connect/connect";
import { quoteActions} from "../../actions";
import axios from "axios";
import {withRouter} from "react-router-dom";
import {loadReCaptcha, ReCaptcha} from 'react-recaptcha-v3'

export class SendQuote extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }

  }

  handleSubmit(event) {
    event.preventDefault();
    this.updateToken() // use captcha
    //this.submitQuote() // not use captcha
  }
  
  submitQuote() {
    const form = document.getElementById("sendQuote_form");
    const data = new FormData(form);
    let json = {}
    for (let name of data.keys()) {
      const input = form.elements[name];
      json[name] = input.value;
    }
    if(!json.email) return
    let {models, install, screenshots, quoteNote, userId, customColors, total, dimension} = this.props.quote.modelData;
    json['models'] = models;
    json['install'] = install;
    json['screenshots'] = screenshots;
    json['quoteNote'] = quoteNote;
    json['customColors'] = customColors;
    json['userId'] = userId;
    json['total'] = total;
    json['dimension'] = dimension;
    this.setState({ loading: true });
    axios.post('/server/sendQuotes', json).then (() => {
      this.props.history.push("/getquote")
    }, (e) => {
      console.log(e)
      this.setState({loading: false})
    });
  }
  
  verifyCallback = (recaptchaToken) => {
    axios.post('/server/verifyCaptcha', {token: recaptchaToken}).then (() => {
      this.submitQuote()
    }, (e) => {
      console.log(e)
      this.setState({loading: false})
    });
  }

  updateToken = () => {
    // you will get a new token in verifyCallback
    this.recaptcha.execute();
  }

  render() {
    return (
      <div>
        <Header/>
        <div className="form-fullpage p-4">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12 col-md-6 col-lg-4">
                <form className="login-form" id="sendQuote_form" method="POST" onSubmit={(e) => this.handleSubmit(e)}>
                  {this.state.loading && (
                    <div className="login-loading">
                      <div className="babylon-loading" id="babylon-loading" ref={ (l) => this.loadingElement = l}>
                        <div className="babylon-loading-child">
                          <img className="babylon-loading-img fa-spin" src="/images/loading.svg"/>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="text-center mb-4">
                    <h6 className="text-uppercase">Customer Details</h6>
                    <h6>Fill in your details for your AlfrescoPlus outdoor kitchen design to be emailed to you.</h6>
                  </div>
                  <div className="form-group" id="errorLogin">
                  </div>
                  <div className="form-group">
                    <input type="text" id="firstName" name="firstName" className="form-control" placeholder="Firstname*" required/>
                  </div>
                  <div className="form-group">
                    <input type="text" id="lastName" name="lastName" className="form-control" placeholder="Lastname*" required/>
                  </div>
                  <div className="form-group">
                    <input type="text" id="address" name="address" className="form-control" placeholder="Home Address*" required/>
                  </div>
                  <div className="form-group">
                    <input type="text" id="postcode" name="postcode" className="form-control" placeholder="Postcode*" required/>
                  </div>
                  <div className="form-group">
                    <input type="number" id="phone" name="phone" className="form-control" placeholder="Phone Number*" required/>
                  </div>
                  <div className="form-group">
                    <input type="text" id="email" name="email" className="form-control" placeholder="Email*" required/>
                  </div>
                  <ReCaptcha
                    ref={ref => this.recaptcha = ref}
                    sitekey="6LeOrrYZAAAAAGAWRknYTqQTv2u6thXUhHMZ8Ylr"
                    action='submit_quote'
                    verifyCallback={this.verifyCallback}
                  />
                  <div className="form-group">
                    <div className="row justify-content-center">
                      <div className="col-12 col-lg-6 text-center">
                        <div className="login-action mt-2 text-uppercase">
                          <button type="submit" className="btn btn-block btn-success mb-3 text-uppercase">Email as PDF</button>
                          <a onClick={() => this.props.history.push("/getquote")} className="login-link text-muted">Go back</a>
                          <small>This site is protected by reCAPTCHA and the Google&nbsp;
                            <a href="https://policies.google.com/privacy">Privacy Policy</a>&nbsp;and&nbsp;
                            <a href="https://policies.google.com/terms">Terms of Service</a>&nbsp;apply.
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }

}

function mapStateToProps(state) {
  const {filter, mainpage, quote} = state;
  return {
    filter, mainpage, quote
  };
}

const actionCreators = {
  emailQuote: quoteActions.emailQuote,
  updateQuote: quoteActions.updateQuote,
};


export default connect(mapStateToProps,  {actionCreators})(withRouter(SendQuote));

