import React, {Component} from 'react';
import moment from 'moment';
import Modal from './Modal';
import {quoteActions} from "../../actions";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";

class Scene extends Component {
  constructor(props) {
    super(props);
  }

  loadBuild = (quoteId, index) => {
    let { user } = this.props;
    if (user) {
      if (typeof user === 'string') {
        user = JSON.parse(user);
      }
      this.props.getQuoteById(user.id, quoteId);
      this.props.history.push("/main");
    }
  };

  download = (params) => {
    this.props.downloadPDF({filePath: params.filePath || ''});
  }

  render() {
    const { quote: { quotes = [] }, searchText } = this.props;

    return(
      <div>
        <div >
          <table className="table history-table">
            <thead className='history-table-header'>
              <tr>
                <th className='padding-left-row' scope="col">Date</th>
                <th scope="col">Quote number</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th>{''}</th>
              </tr>
            </thead>
            <tbody>
            {
              quotes.filter(e => {
                return ((e.first_name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) || (e.last_name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
                    || (e.email.toLowerCase().indexOf(searchText.toLowerCase()) !== -1))
              }).map((data, index) => (
                <tr key={index.toLocaleString()}>
                  <th className='padding-left-row'  scope="row">{moment(data.createdAt).format('DD/MM/YYYY')}</th>
                  <td>{data.id}</td>
                  <td>{`${data.first_name} ${data.last_name}`}</td>
                  <td>{data.email}</td>
                  <td>{data.phone}</td>
                  <td>
                    <button className="btn btn-secondary mr-1" type="button" onClick={() => this.download(data, index)}>DOWNLOAD PDF</button>
                    <button className="btn btn-secondary" type="button" onClick={() => this.loadBuild(data.id, index)}> LOAD BUILD </button>
                  </td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </div>
        <Modal
          {...this.props}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {filter, mainpage, quote: { quoteFile = null }, authentication: { user = {}}} = state;
  console.log('state', state)
  return {
    filter, mainpage, user, quoteFile
  };
}

const actionCreators = {
  downloadPDF: quoteActions.downloadPDF,
};

const quoteTable = connect(mapStateToProps, actionCreators)(withRouter(Scene));
export { quoteTable as QuoteHistoryTable };

