import {authHeader} from '../helpers';

const apiUrl = "/server/";

export const quoteService = {
  emailQuote,
  updateQuote,
  getQuotes,
  getQuoteById,
  downloadPDF,
};

function getQuotes(userId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/${userId}/quotes`, requestOptions).then(handleResponse);
}

function getQuoteById(userId, quoteId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/${userId}/quotes/${quoteId}`, requestOptions).then(handleResponse);
}

function emailQuote() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/emailQuote`, requestOptions).then(handleResponse);
}

function updateQuote() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/updateQuote`, requestOptions).then(handleResponse);
}

function downloadPDF(params) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(params)
  };

  return fetch(`${apiUrl}/download`, requestOptions).then(res => {
    if(!res.ok) {
      return null;
    }
    return res.blob();
  });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        //logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    console.log(data)

    return data;
  });
}
