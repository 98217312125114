import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import {userActions} from '../../actions';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        firstName: '',
        lastName: '',
        username: '',
        password: ''
      },
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    console.log(this.props)
  }

  handleChange(event) {
    const {name, value} = event.target;
    const {user} = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({submitted: true});
    const {user} = this.state;
    if (user.firstName && user.lastName && user.username && user.password) {
      this.props.register(user, this.props.history);
    }
  }

  render() {
    const {registering, message} = this.props;
    const {user, submitted} = this.state;

    return (
      <div className="login p-4">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-lg-4">
              <form className="login-form" method="POST">
                <div className="text-center mb-4">
                  <img src="/images/logo.png"/>
                </div>
                <div className="form-group" id="errorLogin">
                </div>
                <div className={'form-group' + (submitted && !user.firstName ? ' has-error' : '')}>
                  <label htmlFor="firstName">First Name</label>
                  <input type="text" className="form-control" name="firstName" value={user.firstName} onChange={this.handleChange} />
                  {submitted && !user.firstName &&
                  <div className="help-block">First Name is required</div>
                  }
                </div>
                <div className={'form-group' + (submitted && !user.lastName ? ' has-error' : '')}>
                  <label htmlFor="lastName">Last Name</label>
                  <input type="text" className="form-control" name="lastName" value={user.lastName} onChange={this.handleChange} />
                  {submitted && !user.lastName &&
                  <div className="help-block">Last Name is required</div>
                  }
                </div>
                <div className={'form-group' + (submitted && !user.username ? ' has-error' : '')}>
                  <label htmlFor="username">Email</label>
                  <input type="email" className="form-control" name="username" value={user.username} onChange={this.handleChange} />
                  {submitted && !user.username &&
                  <div className="help-block">Email is required</div>
                  }
                </div>
                <div className={'form-group' + (submitted && !user.password ? ' has-error' : '')}>
                  <label htmlFor="password">Password</label>
                  <input type="password" className="form-control" name="password" value={user.password} onChange={this.handleChange} />
                  {submitted && !user.password &&
                  <div className="help-block">Password is required</div>
                  }
                </div>
                <div className="form-group">
                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-6 text-center">
                      <div className="login-action mt-2">
                        <button type="submit" className="btn btn-block btn-success mb-3" onClick={this.handleSubmit}>Create Account</button>
                        <Link to="/login" className="login-link btn btn-black-border">Go back</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

          </div>
          <div className="login-footer text-center">
            <img src="/images/bunnings-logo-colour.png" alt="wavehouse"/>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const {registering} = state.registration;
  const {message = null} = state.alert;
  return {registering, message};
}

const actionCreators = {
  register: userActions.register
}

const connectedRegisterPage = connect(mapState, actionCreators)(withRouter(RegisterPage));
export {connectedRegisterPage as RegisterPage};
