import React, {Component} from 'react';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import $ from 'jquery';
window.jQuery = $;

const listProducts = [
  { name: 'BBQs & BBQ MODULES', type: 'BBQ', icon: 'icon-products-bbqs.png', iconActive: 'icon-products-bbqs-white.png' },
  //{ name: 'Taps & Mixer', type: 'Taps & Mixer', icon: 'icon-products-sinks.png', iconActive: 'icon-products-sinks-white.png' },
  { name: 'FRIDGE & FRIDGE MODULES', type: 'Fridge', icon: 'icon-products-fridges.png', iconActive: 'icon-products-fridges-white.png' },
]

const listColorCategories = [
  { name: 'Cabinets', type: 'CABINET', icon: 'icon-style-cabinet.png', iconActive: 'icon-style-cabinet-white.png' },
  //{ name: 'Benchtops', type: 'BENCHTOP', icon: 'icon-style-benchtop.png', iconActive: 'icon-style-benchtop-white.png' },
  //{ name: 'Fixtures', type: 'FIXTURE', icon: 'icon-style-fixture.png', iconActive: 'icon-style-fixture-white.png' },
]

const listCabinetCategories = [
  //{ name: 'Drawers', type: 'Drawer', icon: 'icon-cabinets-drawers.png', iconActive: 'icon-cabinets-drawers-white.png' },
  { name: 'MODULES', type: 'Cupboard', icon: 'icon-cabinets-cupboards.png', iconActive: 'icon-cabinets-cupboards-white.png' },
  { name: 'SINK MODULES', type: 'Taps & Mixer', icon: 'icon-products-sinks.png', iconActive: 'icon-products-sinks-white.png' },
]

export default class SideBar extends Component {
  rootURL = "/assets/model/"

  state = {
    selectedCategory: 'BBQ',
    searchFilter: '',
    popoverOpen: false,
    currentMenu : 'modules',
    selectedColor: {},
    colorId: null
  };
  initCompoment = false;

  componentDidMount() {
    this.setState({selectedColor: this.props.selectedColors});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
     if($('.collapse').length && !this.initCompoment) {
        $('.collapse').on('show.bs.collapse', function () {
            $('.collapse.show').each(function(){
                $(this).collapse('hide');
            });
        });
         this.initCompoment = true;
     }
  }

  onDragStart = (event, id) => {
    event.dataTransfer.dropEffect = 'copy';
    event.dataTransfer.setData("text/plain", id + "");
    event.target.style.opacity = "0.4";
  }

  onDragEnd = (event) => {
    event.target.style.opacity = "1";
  }

  onSelect = key => {
    this.setState({ selectedCategory: key });
  }

  getMenuList = () => listProducts.map(el => {
    const { name, type, icon, iconActive} = el;
    const {selectedCategory} = this.state;
    //const ctIcon = selectedCategory === type ? iconActive : icon;
    const ctIcon = selectedCategory === type ? icon : iconActive;

    return (
      // <div className= {'col nav-item menu-item-container ' + (selectedCategory === type ? 'active' : '')}  key={type} onClick={() => this.onSelect(type)}>
      <div className= {'col nav-item menu-item-container ' + (selectedCategory === type ? '' : 'active')}  key={type} onClick={() => this.onSelect(type)}>
        <img className='menu-item' src={ 'images/icons/' + ctIcon}></img>
        <div className="name"> {name} </div>
      </div>
    );
  });

  getMenuColorList = () => listColorCategories.map(el => {
    const { name , icon, iconActive, type } = el;
    const {selectedCategory} = this.state;
    //const ctIcon = selectedCategory === type ? iconActive : icon;
    const ctIcon = selectedCategory === type ? icon : iconActive;

    return (
      //<div className= {'col-4 nav-item menu-item-container ' + (selectedCategory === type ? 'active' : '')}  key={type} onClick={() => this.onSelect(type)}>
      <div className= {'col-4 nav-item menu-item-container ' + (selectedCategory === type ? '' : 'active')}  key={type} onClick={() => this.onSelect(type)}>
        <img className='menu-item' src={ 'images/icons/' + ctIcon}></img>
        <div className="name"> {name}</div>
      </div>
    );
  });

  getMenuCabinetList = () => listCabinetCategories.map(el => {
    const { name , icon, iconActive, type } = el;
    const {selectedCategory} = this.state;
    //const ctIcon = selectedCategory === type ? iconActive : icon;
    const ctIcon = selectedCategory === type ? icon : iconActive;

    return (
      //<div className= {'col-4 nav-item menu-item-container ' + (selectedCategory === type ? 'active' : '')}  key={type} onClick={() => this.onSelect(type)}>
      <div className= {'col-4 nav-item menu-item-container ' + (selectedCategory === type ? '' : 'active')}  key={type} onClick={() => this.onSelect(type)}>
        <img className='menu-item' src={ 'images/icons/' + ctIcon}></img>
        <div className="name"> {name}</div>
      </div>
    );
  });

  renderButton = (item) => {
    const {items, onAddItem, onRemoveItem, listModels} = this.props;
    if(this.props.loading || this.state.loading || this.state.selectedItem !== item.id) return
    if(this.state.selectedItem == item.id) {
      if(listModels.length > 0) {
        return (
            <div className="d-flex btn-add" style={{padding: 5}}>
              <Button className='col-md-5 btn btn-success'  type="button"
                      onClick={() => {
                        this.setState({loading: true})
                        onAddItem({...item, side: 'left'})
                        this.setState({loading: false})
                      }}>Add Left</Button>
              <Button className='col-md-5 btn btn-success'  style={{float: 'right'}} type="button"
                      onClick={() => {
                        this.setState({loading: true})
                        onAddItem({...item, side: 'right'})
                        this.setState({loading: false})
                      }}>Add Right</Button>
            </div>
        )
      } else {
        return (
            <div className="d-flex" style={{padding: 5}}>
              <Button className='col-md-6 off-3 btn btn-success' disabled={!!listModels.find(i =>  i.id === item.id)} type="button"
                      onClick={() => {
                        this.setState({loading: true})
                        onAddItem(item)
                        this.setState({loading: false})
                      }}>Add Item</Button>
            </div>
        )
      }
    }
  }

  productDetailContent = (item) => {
    return (
      <div className="product-detail">
        <a onClick={this.goBack.bind(this)} className="go-back"><i className="fas fa-arrow-left"></i></a>
        <p>{item.name}</p>
        <p>{`${item.width}W x ${item.depth}D x ${item.height}H`}</p>
        <p className="product-price font-weight-bold">{`$${item.price}`}</p>
      </div>)
  }

  goBack = (e) => {
    this.setState({selectedItem: null});
  }

  render() {
    const { selectedCategory, searchFilter } = this.state;
    let {items, onSelectItem, colors, productMenus} = this.props;

    if (productMenus && productMenus.length && items && items.length) {
      productMenus.sort((a,b) => a.menuId > b.menuId ? 1 : ((b.menuId > a.menuId) ? -1 : 0));
      let arrOrderList = []
      let arrUnOrderList = []

      for (let i = 0; i < productMenus.length; i++) {
        const ele = productMenus[i];
        const findResult = items.find(subEle => subEle.id == ele.productId)
        if (findResult) {
          arrOrderList.push(findResult)
        }
      }

      if (arrOrderList && arrOrderList.length) {
        arrUnOrderList = items.filter(o1 => !arrOrderList.some(o2 => o1.id === o2.id));
      }
           
      items = [...arrOrderList, ...arrUnOrderList]
    }

    if (selectedCategory) {
      items = items.filter(i => i.category === selectedCategory);
    }

    return(
      <div>
        <nav className="main-sidebar">
          <div className="sidebar-tabs">

            <div className="panel-group" id="accordion">
              {/* <div className={this.state.currentMenu === 'modules' ? "active panel panel-default" : "panel panel-default"}> */}
              <div className={this.state.currentMenu === 'modules' ? "panel panel-default" : "active panel panel-default"}>
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <a className="accordion-toggle" onClick={() => this.setState({currentMenu: 'modules', selectedCategory: 'BBQ', selectedItem: null })}
                       data-toggle="collapse" aria-expanded="true" data-parent="#accordion" href="#collapseOne">
                      Select Appliances
                      <i className="fas fa-chevron-right"></i>
                    </a>
                  </h4>
                </div>
                <div id="collapseOne" className="panel-collapse collapse show">
                  <div className="panel-body">
                    <div className="sidebar-tabs-header category-list">
                      <div className="row">
                        {this.getMenuList()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className={this.state.currentMenu === 'cabinet' ? "active panel panel-default" : "panel panel-default"}> */}
              <div className={this.state.currentMenu === 'cabinet' ? "panel panel-default" : "active panel panel-default"}>
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <a className="accordion-toggle" onClick={() => this.setState({currentMenu: 'cabinet', selectedCategory: 'Cupboard', selectedItem: null}) } data-toggle="collapse" data-target="#collapseThree" href="#collapseThree">
                      Choose Cabinets
                      <i className="fas fa-chevron-right"></i>
                    </a>
                  </h4>
                </div>
                <div id="collapseThree" className="panel-collapse collapse">
                  <div className="panel-body">
                    <div className="sidebar-tabs-header category-list">
                      <div className="row">
                        {this.getMenuCabinetList()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className={this.state.currentMenu === 'colors' ? "active panel panel-default" : "panel panel-default"}> */}
              <div className={this.state.currentMenu === 'colors' ? "panel panel-default" : "active panel panel-default"}>
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <a className="accordion-toggle" onClick={() => this.setState({currentMenu: 'colors', selectedCategory: 'CABINET', selectedItem: null}) } data-toggle="collapse" data-target="#collapseTwo" href="#collapseTwo">
                      Pick Colours
                      <i className="fas fa-chevron-right"></i>
                    </a>
                  </h4>
                </div>
                <div id="collapseTwo" className="panel-collapse collapse">
                  <div className="panel-body">
                    <div className="sidebar-tabs-header category-list">
                      {/*<ScrollMenu
                        data={this.getMenuColorList()}
                        arrowLeft={ <div className='arrow-prev'><i className="fas fa-angle-left"></i></div>}
                        arrowRight={ <div className='arrow-next'><i className="fas fa-angle-right"></i></div>}
                        selected={selectedCategory}
                        onSelect={(key) => this.onSelect(key)}
                        dragging={false}
                        hideArrows={true}
                      />*/}
                      <div className="row">
                        {this.getMenuColorList()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className='search-container'>
                <input onChange={(e) =>{ console.log( e.target.value) ; this.setState({searchFilter: e.target.value}) } } type="text" className="form-control search-item" placeholder="Search ..."/>
              </div>
              <div className="sidebar-tabs-body style-scroll">
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="list-home" role="tabpanel"
                       aria-labelledby="list-home-list">

                    <div className="tabs-list">
                      <div id="collapseOne" className="panel-collapse collapse show">
                      {
                        this.state.currentMenu === 'modules' && items && items.filter(item => (!(searchFilter !== '' && item.name.toLowerCase().search(searchFilter.toLowerCase()) === -1)) && (item.category !== 'Corner'))
                          .map(item => (
                            <div className={this.state.selectedItem === item.id ? "tabs-item active" : "tabs-item"} key={item.id} onClick={() => {
                              if (this.state.selectedItem === item.id) {
                                return  this.setState({selectedItem: null});
                              }
                              this.setState({selectedItem: item.id});
                              this.props.onSelectItem(item);
                            }}>
                                <div style={{opacity: this.props.loading ? 0.5 : 1}}>
                                  <a  className="" draggable="true" onDragStart={(e) => this.onDragStart(e, item.id)} onDragEnd={this.onDragEnd} data-modelid={item.id}>
                                    <img id={'Popover-' + item.id} src={item.thumbnail ? '/images/thumbnail/' + item.thumbnail : '/images/product-img.png'} className="img-fluid" alt="product"
                                         />
                                  </a>

                                  <div className="wrap-details">
                                    <div className='item-info d-flex'>
                                      <div className='item-name'>{item.name}</div>
                                    </div>
                                    {!this.props.loading && this.renderButton(item)}
                                  </div>
                                </div>
                              {this.state.selectedItem === item.id && <Popover target={'Popover-' + item.id}
                                         boundariesElement={"main-wrapper"}
                                         isOpen={this.state.selectedItem === item.id}
                                         placement="left"
                                         hideArrow={true}
                                         className={'product-detail'}
                                >
                                  <a  onClick={this.goBack.bind(this)} className="go-back"><i className="fas fa-arrow-left"></i></a>
                                  <p>{item.name}</p>
                                  <p>{`${item.width}W x ${item.depth}D x ${item.height}H`}</p>
                                  <p className="product-price font-weight-bold">{`$${item.price}`}</p>
                                </Popover>}

                            </div>
                          ))
                      }
                      </div>
                      <div id="collapseThree" className="panel-collapse collapse">
                        {
                          this.state.currentMenu === 'cabinet' && items && items.filter(item => (!(searchFilter !== '' && item.name.toLowerCase().search(searchFilter.toLowerCase()) === -1)) && (item.category !== 'Corner'))
                            .map(item => (
                              <div className={this.state.selectedItem === item.id ? "tabs-item active" : "tabs-item"} key={item.id} onClick={() => {
                                if (this.state.selectedItem === item.id) {
                                  return  this.setState({selectedItem: null});
                                }
                                this.setState({selectedItem: item.id});
                                this.props.onSelectItem(item);
                              }}>
                                <div style={{opacity: this.props.loading ? 0.5 : 1}}>
                                  <a  className="" draggable="true" onDragStart={(e) => this.onDragStart(e, item.id)} onDragEnd={this.onDragEnd} data-modelid={item.id}>
                                    <img id={'Popover-' + item.id} src={item.thumbnail ? '/images/thumbnail/' + item.thumbnail : '/images/product-img.png'} className="img-fluid" alt="product"
                                    />
                                  </a>

                                  <div className='item-info d-flex'>
                                    <div className='item-name'>{item.name}</div>
                                  </div>
                                  {!this.props.loading && this.renderButton(item)}
                                </div>
                                {this.state.selectedItem === item.id && <Popover target={'Popover-' + item.id}
                                                                                 boundariesElement={"main-wrapper"}
                                                                                 isOpen={this.state.selectedItem === item.id}
                                                                                 placement="left"
                                                                                 hideArrow={true}
                                                                                 className={'product-detail'}
                                >
                                  <a  onClick={this.goBack.bind(this)} className="go-back"><i className="fas fa-arrow-left"></i></a>
                                  <p>{item.name}</p>
                                  <p>{`${item.width}W x ${item.depth}D x ${item.height}H`}</p>
                                  <p className="product-price font-weight-bold">{`$${item.price}`}</p>
                                </Popover>}

                              </div>
                            ))
                        }
                      </div>
                      <div id="collapseTwo" className="panel-collapse collapse">
                      {
                        this.state.currentMenu === 'colors' && colors && colors.filter(item => (!(searchFilter !== '' && item.name.toLowerCase().search(searchFilter.toLowerCase()) === -1) && (selectedCategory ? item.type === selectedCategory : true)))
                          .map(item => item.type == 'FIXTURE' ? (
                            <div style={this.props.selectedColors[item.type] && this.props.selectedColors[item.type].id === item.id  ? {border: "1px rgba(0,0,0,0.2) solid", textDecoration: 'underline'} : {}} className="tabs-item colors-item" key={item.id} onClick={() => {
                              let newColor = {...this.state.selectedColor, [item.type] : item};
                              this.props.changeAllModelColor(newColor);
                            }}>
                              <a  className="colors-item"
                                style={{
                                "backgroundImage": `url('/images/thumbnail/${item.path}thumbnail.png')`,
                                "backgroundSize": "cover",
                                "backgroundRepeat": "no-repeat",
                                "backgroundPosition": "center center",
                                "backgroundColor": "#ececec",
                                "cursor": "pointer",
                                "height": "200px"
                              }}>
                              </a>

                                <div className='item-info d-flex'>
                                  <div className='item-name'>{item.name}</div>
                                  <img className="info-icon" src={
                                    this.props.selectedColors[item.type] && this.props.selectedColors[item.type].id === item.id ?
                                      '/images/icons/icon_radio_button_checked_24px.png' : '/images/icons/icon_radio_button_unchecked_24px.png'}  />
                                </div>
                            </div>
                          ): (
                              <div style={this.props.selectedColors[item.type] && this.props.selectedColors[item.type].id === item.id  ? {border: "1px rgba(0,0,0,0.2) solid", textDecoration: 'underline'} : {}} className="tabs-item colors-item" key={item.id} onClick={() => {
                                let newColor = {...this.state.selectedColor, [item.type] : item};
                                this.props.changeAllModelColor(newColor);
                              }}>
                                <a  className="colors-item"
                                   style={{
                                     "backgroundImage": "url('" + this.rootURL + item.path || '/images/product-img.png' + "')",
                                     "backgroundSize": "cover",
                                     "backgroundRepeat": "no-repeat",
                                     "backgroundPosition": "center center",
                                     "backgroundColor": "#ececec",
                                     "cursor": "pointer"
                                   }}>
                                </a>

                                <div className='item-info d-flex'>
                                  <div className='item-name'>{item.name}</div>
                                  <img className="info-icon" src={
                                    this.props.selectedColors[item.type] && this.props.selectedColors[item.type].id === item.id ?
                                      '/images/icons/icon_radio_button_checked_24px.png' : '/images/icons/icon_radio_button_unchecked_24px.png'}  />
                                </div>
                              </div>
                            )
                          )
                      }
                    </div>
                  </div>
                  </div>
                  <div className="tab-pane fade" id="list-profile" role="tabpanel"
                       aria-labelledby="list-profile-list">...
                  </div>
                  <div className="tab-pane fade" id="list-messages" role="tabpanel"
                       aria-labelledby="list-messages-list">...
                  </div>
                </div>
              </div>
            </div>

          </div>
        </nav>
      </div>
    );
  }

}
