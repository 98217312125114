import React, {Component} from 'react';
import {priceFormat} from "../../utils/CommonUtils";
import Slider, { Range } from 'rc-slider';
import {Redirect} from "react-router-dom";

export default class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'price',
      priceRange: null,
      shape: 'I',
      dimensionW: null,
      dimensionD: null
    };
  }


  getPriceRage() {
    return this.state.priceRange ?
      priceFormat(this.state.priceRange[0])  + ' - ' + priceFormat(this.state.priceRange[1])
      : '$0 - $0'
  }


  getDimensionText() {
    let {dimensionW, dimensionD} = this.state;
    return (
      <div className="dimension-text">
        <span>Max Width: {(<strong>{ dimensionW ? dimensionW + 'mm' : '0000mm'}</strong>)}</span>
        <span>Max Depth: {(<strong>{ dimensionD ? dimensionD + 'mm' : '0000mm'}</strong>)}</span>
      </div>
    )
  }

  skip() {
    if (this.state.activeTab === 'shape') {
      this.setState({shape: null, dimensionW: null, dimensionD: null});
      this.props.onFinish && this.props.onFinish();
    }
    if (this.state.activeTab === 'price') {
      this.setState({priceRange: null, activeTab: 'shape'});
    }
  }

  submitFilter() {
    let {priceRange, shape, dimensionW, dimensionD, activeTab} = this.state;
    if (activeTab === 'price') {
      if (!priceRange) {
        this.setState({priceRange: [0,0]});
      }
      this.setState({activeTab: 'shape'});
    }
    if (this.state.activeTab === 'shape') {
      this.props.onSubmitFilter({price: priceRange, shape: {type: shape, w: dimensionW || 0, d: dimensionD || 0}});
      this.props.onFinish && this.props.onFinish();
    }
  }

  render() {
    return(
      <div className="filter p5">
        <div className='container'>
          <div className="filter-page-tab">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 text-center text-uppercase">
                <div className={this.state.activeTab === 'price' ? 'filter-tabs' : 'disabled-tab filter-tabs'}
                     onClick={() => this.setState({activeTab: 'price'})}>
                  Set price range
                </div>
                <div className={this.state.activeTab === 'shape' ? 'filter-tabs' : 'disabled-tab filter-tabs'}
                     onClick={() => this.setState({activeTab: 'shape'})}>
                  Set measurements
                </div>
              </div>
            </div>
          </div>

          {
            this.state.activeTab === 'price' &&
            (<div className="filter-price text-center py-5 mt-5">
              <div className='row'>
                <div className="col-lg-6 offset-lg-3" >
                  Price: <p className='inline-bold-text'>{this.getPriceRage()}</p>
                </div>
                <div className="col-lg-6 offset-lg-3" >
                  <Range value={this.state.priceRange ? this.state.priceRange : [0,0]} min={0} max={20000} onChange={val => this.setState({priceRange: val})}/>
                </div>
              </div>
            </div>)
          }

          {
            this.state.activeTab === 'shape' &&
            (
              <div className="filter-price text-center py-5">
               {/* <div className='row text-uppercase font-bold'>
                  <div className="col-lg-6 offset-lg-3 filter-type" >
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                             onChange={() => this.setState({shape: 'I'})} defaultChecked />
                      <label className="form-check-label disabled-tab"
                             style={this.state.shape === 'I' ? {opacity: 1} : {} }
                             htmlFor="inlineRadio1">Straight</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                             onChange={() => this.setState({shape: 'L'})}/>
                      <label className="form-check-label disabled-tab"
                             style={this.state.shape === 'L' ? {opacity: 1} : {} }
                             htmlFor="inlineRadio2">L-Shape</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3"
                             onChange={() => this.setState({shape: 'U'})}/>
                      <label className="form-check-label disabled-tab"
                             style={this.state.shape === 'U' ? {opacity: 1} : {} }
                             htmlFor="inlineRadio3">U-Shape</label>
                    </div>
                  </div>
                </div>*/}
                <div className='row mt-4'>
                  <div className="col-lg-6 offset-lg-3" >
                    {this.getDimensionText()}
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className="col-lg-4 offset-lg-2">
                    <div className="dimension-wrap">
                      <Slider value={this.state.dimensionW ? this.state.dimensionW : 0} style={{display: 'inline-block'}}
                              className='dimension-slider dimension-w' min={0} max={1000}
                              onChange={val => this.setState({dimensionW: val})}/>
                      <Slider value={this.state.dimensionD ? this.state.dimensionD : 0} style={{display: 'inline-block'}}
                              className='dimension-slider dimension-d' min={0} max={1000}
                              onChange={val => this.setState({dimensionD: val})}/>
                    </div>
                  </div>
                  <div className="col-lg-4" >
                    {(this.state.shape === 'I' || !this.state.shape) && <img className="img-fluid" src="/images/filter/shape1.png"/>}
                    {this.state.shape === 'L' && <img className="img-fluid" src="/images/filter/shape2.png"/>}
                    {this.state.shape === 'U' && <img className="img-fluid" src="/images/filter/shape3.png"/>}
                  </div>
                </div>
              </div>)
          }

          <div className="filter-button">
            <div className='row'>
              <div className="col-lg-6 offset-lg-3 text-center">
                <button onClick={() => {console.log(this); this.skip()}} type="button" className="btn btn-secondary font-bold text-uppercase">Skip All</button>
                {
                  this.state.activeTab === 'price' &&
                  (<button onClick={() => this.submitFilter()}
                           type="button" className="btn btn-success font-bold text-uppercase">Set price</button>)
                }
                {
                  this.state.activeTab === 'shape' &&
                  ( <button type="button" onClick={() => this.submitFilter()} className="btn btn-success font-bold text-uppercase">Set measurements</button>)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}
