import React, { Component } from 'react';

class Modal extends Component {

  componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data,
    });
  }

  render() {
    const { quote: { quoteById = {} } = {} } = this.props;
    const { details } = quoteById;
    const data = details ? details[0].data || null : null;
    console.log(data);

    return (
      <div className="modal fade dapp-white" id="quoteModal" tabIndex="-1" role="dialog"
           aria-labelledby="installModalLabel" data-backdrop="static" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Quote Detail</h3>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;