import React from 'react';
import ReactDOM from 'react-dom';

import '../node_modules/bootstrap/dist/css/bootstrap.css';
import './assets/scss/index.scss';
import '../node_modules/bootstrap/dist/js/bootstrap.js';

import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
