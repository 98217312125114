import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../actions';
import { LoginPage } from './accounts/Login';
import MainPage from "../containers/MainPage";

class Homepage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    //this.props.logout();

    this.state = {
      username: '',
      password: '',
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { username, password } = this.state;
    if (username && password) {
      this.props.login(username, password);
    }
  }

  render() {
    const { loggedIn } = this.props;
    //if (loggedIn) {
    return (
      <MainPage />
    )
    //}

    /*return (
      <div className="">
        <LoginPage/>
      </div>
    )*/
  }
}

function mapState(state) {
  const { loggedIn } = state.authentication;
  return { loggedIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
};

const connectedHomepage = connect(mapState, actionCreators)(withRouter(Homepage));
export { connectedHomepage as Homepage };
