import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import Mainpage from './containers/MainPage';
import AnotherPage from './components/AnotherPage';
import { LoginPage } from './components/accounts/Login';
import { Homepage } from './components/Homepage';
import { RegisterPage } from './components/accounts/RegisterPage';
import { GetQuote } from "./containers/get_quote/GetQuote";
import SendQuote from "./containers/get_quote/SendQuote";
import NotFound from './components/NotFound';
import reducers from './reducers';
import { Profile } from "./containers/Profile";
import { loadReCaptcha } from 'react-recaptcha-v3'

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const store = createStoreWithMiddleware(reducers);
loadReCaptcha("6LeOrrYZAAAAAGAWRknYTqQTv2u6thXUhHMZ8Ylr")

const App = () => (
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Homepage}/>
        <Route path="/another_page" component={AnotherPage}/>
        <Route path="/login" component={LoginPage}/>
        <Route path="/signup" component={RegisterPage}/>
        {/*<Route path="/filter" component={Filter} />*/}
        <Route path="/profile" component={Profile} />
        <Route path="/main" component={Mainpage}/>
        <Route path="/getquote" component={GetQuote}/>
        <Route path="/sendquote" component={SendQuote}/>
        <Route component={NotFound}/>
      </Switch>
    </Router>
  </Provider>
);

export default App;
