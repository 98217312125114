import {FETCH_ITEMS, SUBMIT_MODELS, FETCH_COLORS, UPDATE_MAIN_MODELS, FETCH_PRODUCT_MENUS, FETCH_SITE_UNDER_CONSTRUCTION} from '../actions/types';

const DEFAULT_STATE = {
  items: [],
  colors: [],
  productMenus: []
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_ITEMS:
      return {...state, items: action.items};
    case FETCH_COLORS:
      return {...state, colors: action.colors};
    case FETCH_PRODUCT_MENUS:
      return { ...state, productMenus: action.productMenus };
    case FETCH_SITE_UNDER_CONSTRUCTION:
      return { ...state, siteUnderConstruction: action.siteUnderConstruction };
    case SUBMIT_MODELS:
      const {models, screenshots, customColors} = action;
      return {...state, models, screenshots, customColors};
    case UPDATE_MAIN_MODELS:
      const { quotes: { details } } = action;
      let data = details ? details[0].data || null : null;
      if (typeof data === 'string') {
        data = JSON.parse(data)
      }
      if (data) {
        const {models, screenshots, customColors} = data;
        return {...state, models, screenshots, customColors};
      } else {
        return {...state};
      }
    default:
      return state;
  }
};
