import { quoteConstants } from '../constants';

export function quote(state = {}, action) {
  switch (action.type) {
    case quoteConstants.SUBMIT_MODEL_QUOTE:
      return { ...state,
        modelData: action.data
      };
    case quoteConstants.GET_QUOTES_SUCCESS:
      return { ...state,
        quotes: action.quotes
      };
    case quoteConstants.GET_QUOTE_BY_ID_SUCCESS:
      return { ...state,
        quoteById: action.quotes
      };
    case quoteConstants.DOWNLOAD_SUCCESS:
      console.log(action)
      return { ...state,
        quoteFile: action.result
      };
    default:
      return state
  }
}