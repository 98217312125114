import React, {Component} from 'react';
import ScrollMenu from "react-horizontal-scrolling-menu";
import Slider from "rc-slider";

const $ = window.$;

export default class Configuration extends Component {
    constructor(props) {
        super(props);
        this.rootURL = "/assets/model/"
        this.state = {
            isShowConfig: true,
            selectedMaterial: 0,
            color: {},
            size: {},
            changeColor: {},
            changeSize: {},
            rotateModule: 0,
            showSlider: false,
            model: {},
            sliderMin: 0,
            sliderMax: 100,
            cornerModel: ''
        }

        this.toggleConfig = this.toggleConfig.bind(this);
        this.openModelSlider = this.openModelSlider.bind(this);
        this.changeModelWidth = this.changeModelWidth.bind(this);
    }

    componentDidMount() {

    }


    toggleConfig() {
        this.setState({isShowConfig: !this.state.isShowConfig})
    }

    onChangeColor = (event, model, TYPE) => {
        this.setState({
            color: {
                ...this.state.color,
                [model.id + '-' + TYPE]: event.target.value
            }
        })
    }
    onSelectColor = (model, TYPE) => {
        this.props.onSelectColor(model, JSON.parse(this.state.color[model.id + '-' + TYPE]));
        this.setState({changeColor: {...this.state.changeColor, [model.id + '_' + TYPE]: false}});
    }

    renderColorConfig = (model, i, TYPE) => {
        const {colors} = this.props
        let {color, changeColor} = this.state
        return !changeColor[i + '_' + TYPE] ? (
            <div className="row">
                <div className="col-lg-9 col-md-9">
                    <span>{TYPE + ' color: ' + (color[model.id + '-' + TYPE] ? JSON.parse(color[model.id + '-' + TYPE]).name : 'default')}</span>
                </div>
                <div className="col-lg-3 col-md-3 link-wrapper">
                    <a onClick={(e) => this.setState({
                        changeColor: {
                            ...changeColor,
                            [i + '_' + TYPE]: true
                        }
                    })} className='item-link'>Change</a>
                </div>
            </div>
        ) : (
            <div className="row">
                <div className="col-lg-9 col-md-9">
                    <span>{TYPE + ' color: '}</span>
                    <select onChange={(e) => this.onChangeColor(e, model, TYPE)}
                            defaultValue={color[model.id + '-' + TYPE] ? color[model.id + '-' + TYPE] : ''}>
                        {colors.filter(c => c.type.toLowerCase() === TYPE.toLowerCase()).map(c => (
                            <option key={c.id} value={JSON.stringify(c)}>{c.name}</option>
                        ))}
                    </select>
                </div>
                <div className="col-lg-3 col-md-3 link-wrapper">
                    <a onClick={(e) => this.onSelectColor(model, TYPE)} className='item-link item-change'>Set color</a>
                </div>
            </div>
        );
    }

    getMaterialList = (materials) => {
        let currentSelect = this.state.selectedMaterial ? this.state.selectedMaterial : materials[0].name;
        return materials.map(material => {
            return (
                <div className={'material-item ' + (currentSelect === material.name ? 'material-item-active' : '')}
                     style={{backgroundColor: material.name}} key={material.name}>

                </div>
            );
        });
    }

    onSelectMaterial = key => {
        this.setState({selectedMaterial: key});
    }

    openModelSlider = model => {
        const {sizes} = model;
        try {
            this.setState({
                showSlider: true,
                sliderMin: sizes[0].width || 0,
                sliderMax: sizes[sizes.length - 1].width || 0,
                model: model
            });
        } catch (e) {
            console.log(e)
        }

    }

    changeModelWidth = val => {
        this.setState({size: {...this.state.size, [this.state.model.id]: val}});
        if(this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.props.onSelectSize(this.state.model, this.state.size[this.state.model.id]);
        }, 500);
    }

    render() {
        const {selectedItem} = this.props;
        const listModels = this.props.listModels.filter(model => model.name !== 'End Panel' && model.category !== 'Corner');
        return (
            <div
                className={"babylon-config " + (this.state.isShowConfig ? 'show' : 'not-show') + (listModels && listModels.length > 0 ? '' : ' sr-only')}>
                <div>
                    <div className="config-header">
                        <ul className="config-tabs nav nav-tabs" id="configTabs" role="tablist">
                            <li className="nav-item">
                                <a href="#" className="config-icon" onClick={this.toggleConfig}><i
                                    className={"fas " + (this.state.isShowConfig ? 'fa-minus-circle' : 'fa-plus-circle')}></i></a>
                                <a className="nav-link active" id="home-tab" data-toggle="tab" href="#configuration"
                                   role="tab"
                                   aria-controls="home" aria-selected="true">Configuration</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="profile-tab" data-toggle="tab" href="#materials" role="tab"
                                   aria-controls="profile" aria-selected="false">Materials</a>
                            </li>
                        </ul>
                    </div>
                    <div className="config-content tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="configuration" role="tabpanel"
                             aria-labelledby="nav-home-tab">
                            <div className="config-list">
                                <div className="row row-horizontal style-scroll">
                                    {
                                        listModels.map((model, i) => (
                                            <div className="col-lg-4 col-md-6 config-item" key={model.id}>
                                                <div className="config-product">
                                                    <div className="config-product-img">
                                                        <img src={model.thumbnail ? '/images/thumbnail/' + model.thumbnail : '/images/product-img.png'}
                                                             className="img-fluid"/>
                                                    </div>
                                                    <div className="config-product-info">
                                                        <div className="row">
                                                            <div className="col-lg-9 col-md-9"><b style={{marginBottom: 5}}>{model.name}</b></div>
                                                           {/* {
                                                                i + 1 === listModels.length && model.index != 0 && !this.props.isTurnRight && (
                                                                    <div className="col-lg-3 col-md-3 link-wrapper">
                                                                        <a data-toggle="modal"
                                                                           data-target="#rotationModal"
                                                                           onClick={() => this.setState({currentTurnModel: model})}
                                                                           className='item-link'>Rotate</a>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                i === 0 && model.index != 0 && !this.props.isTurnLeft && (
                                                                    <div className="col-lg-3 col-md-3 link-wrapper">
                                                                        <a data-toggle="modal"
                                                                           data-target="#rotationModal"
                                                                           onClick={() => this.setState({currentTurnModel: model})}
                                                                           className='item-link'>Rotate</a>
                                                                    </div>
                                                                )
                                                            }*/}
                                                        </div>
                                                        {/*{
                                                            this.renderColorConfig(model, i, 'Cabinet')
                                                        }
                                                        {
                                                            this.renderColorConfig(model, i, 'Benchtop')
                                                        }*/}
                                                    </div>
                                                </div>
                                                <div className="config-dimension">
                                                    <div className="config-dimension-remove">
                                                        { (i + 1 === listModels.length  || i === 0)&& model.index !== 0 > 1 && !this.props.loading &&
                                                            <a className="item-link" onClick={() => this.props.onRemoveItem(model)}>Remove</a>
                                                        }
                                                    </div>
                                                    <div className="config-dimension-details">
                                                        <div className="row">
                                                            <div className="col-lg-9 col-md-9">
                                                                <span>Size: {this.state.size[model.id] ? this.state.size[model.id] : model.width}W x {model.depth}D x {model.height}H</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='config-move'>
                                                    <div className='config-move-left' title='Move left'
                                                         onClick={async () => await this.props.moveModel(model, 'left')}>
                                                        <i className="fas fa-angle-left"></i>
                                                    </div>
                                                    <div className='config-move-right' title='Move right'
                                                         onClick={async () => await this.props.moveModel(model, 'right')}>
                                                        <i className="fas fa-angle-right"></i>
                                                    </div>
                                                </div>

                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                        </div>
                        <div className="tab-pane fade" id="materials" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <div className='materials-content row'>
                                <ScrollMenu
                                    arrowLeft={<div className='arrow-prev'><i className="fas fa-angle-left"></i></div>}
                                    arrowRight={<div className='arrow-next'><i className="fas fa-angle-right"></i>
                                    </div>}
                                    selected={this.state.selectedMaterial}
                                    onSelect={(key) => this.onSelectMaterial(key)}/>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showSlider && (
                    <div className="config-modal">
                        <div className="config-modal-header">
                            <h3>Slide to update component width</h3>
                            <a  className="close" onClick={() => {
                                this.setState({showSlider: false})
                            }}>
                                <span aria-hidden="true">&times;</span>
                            </a>
                        </div>
                        <div className="config-filter">
                            <div className="col-md-8 m-auto">
                                <div className="slide-title text-center">Component
                                    Width: {(this.state.size[this.state.model.id] ? this.state.size[this.state.model.id] : this.state.model.sizes.find(c => c.suffix === this.state.model.size_suffix).width)}mm
                                </div>
                                <Slider
                                    value={this.state.size[this.state.model.id] ? this.state.size[this.state.model.id] : this.state.sliderMax}
                                    className='dimension-slider dimension-w'
                                    min={this.state.sliderMin}
                                    max={this.state.sliderMax}
                                    step={10}
                                    onChange={val => this.changeModelWidth(val)}/>
                            </div>
                        </div>
                    </div>
                )}

                <div className="modal fade dapp-modal" id="rotationModal" tabIndex="-1" role="dialog"
                     aria-labelledby="rotationModalLabel" data-backdrop="static" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">Select Corner Block Size</h3>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="rotate-list">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div
                                                className={`config-image ${this.state.cornerModel == 'Corner' ? 'active' : ''}`}
                                                onClick={(e) => {
                                                    this.setState({cornerModel: 'Corner'})
                                                }}>
                                                <img src='/images/rotation/option2.png'/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div
                                                className={`config-image ${this.state.cornerModel == 'Double Corner' ? 'active' : ''}`}
                                                onClick={(e) => {
                                                    this.setState({cornerModel: 'Double Corner'})
                                                }}>
                                                <img src='/images/rotation/option1.png'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="main-footer-bottom mt-3 rotate-action">
                                    <button type="button" className="btn btn-secondary text-uppercase"
                                            data-dismiss="modal">Cancel
                                    </button>
                                    <button data-dismiss="modal" className="btn btn-success" onClick={() => {
                                        if (this.state.cornerModel) {
                                            this.props.turnRotate(this.state.currentTurnModel, this.state.cornerModel)
                                        }
                                    }}>OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
