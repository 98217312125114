import React, {Component} from 'react';
import {connect} from 'react-redux';
import BabylonScene from '../components/Scene';

import Header from "../components/Header";
import SideBar from "../components/mainpage/SideBar";
import {fetchItems, submitFilter, submitModels, fetchColors, fetchProductMenus, fetchSiteUnderConstruction } from "../actions";
import Setting from "../components/filter/Setting";
import Configuration from "../components/mainpage/Configuration";
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {withRouter} from "react-router-dom";
import ImageUploadService from "../services/ImageUploadService";
import {checkExistAsset} from "../utils/CommonUtils";
import $ from 'jquery';
window.jQuery = window.jQuery || $;
var __html = require('./templateSiteUnderConstruction.html');
var template = { __html: __html };

const hardcodeBuffer = {
  "Double Corner Left" : -28.5,
  "Double Corner Right" : -28.5,
  "Corner Left" : 1.5,
  "Corner Right" : 1.5
};

let currentLeftBuffer = 0, currentRightBuffer = 0;

export class MainPage extends Component {

  state = {
    setting: false,
    selectedItem: null,
    listModels: [],
    customAllColor: {
      CABINET: {
        id: 3,
        name: "Cadet Grey",
        type: "CABINET",
        path: "material/Cabinet/Polytec-Cinder.png"
      },
      BENCHTOP: {
        id: 1,
        // name: "Polytec Black",
        type: "BENCHTOP",
        path: "material/Benchtop/Polytec-Black.png"        
      }
    },
    isLoadModels: false,
  };
  rootURL = "/assets/model/"

  componentDidMount() {
    $("#alertModal").modal({show:false})
    let {fetchItems, fetchColors, filter, mainpage, fetchProductMenus, fetchSiteUnderConstruction } = this.props;
    this.leftCount = -1;
    this.rightCount = 0;
    this.isReseting = false;
    this.turnLeft = this.turnRight = false;
    this.listBoxes = [];
    this.availableModels = [];
    // test
   // this.turnRight = true;
    this.lastRightZ = null;
    this.leftWidth = this.rightWidth = this.leftZWidth = this.rightZWidth = 0;
    fetchItems(filter);
    fetchColors();
    fetchProductMenus();
    fetchSiteUnderConstruction();
    this._loadingElement = document.getElementById('babylon-loading')
  }

  componentWillReceiveProps(nextProps) {
    const { models } = this.props.mainpage
    const { models: nextModels, customColors } = nextProps.mainpage
    if (models !== nextModels) {
      if (!this.state.isLoadModels) {
        this.setState({customAllColor : customColors})
        this.loadModels(nextModels);
        this.setState({isLoadModels : true})
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.filter !== this.props.filter) {
      this.props.fetchItems(nextProps.filter);
      return true;
    }
    if (nextProps.mainpage.items !== this.props.mainpage.items) {
      return true;
    }
    if (nextProps.mainpage.colors !== this.props.mainpage.colors) {
      return true;
    }
    if (nextProps.mainpage.productMenus !== this.props.mainpage.productMenus) {
      return true;
    }
    if (nextProps.mainpage.siteUnderConstruction !== this.props.mainpage.siteUnderConstruction) {
      return true;
    }
    return nextState !== this.state;
  }

  openSetting() {
    this.setState({setting: true});
  }

  closeSetting() {
    this.setState({setting: false});
  }

  onSceneMount = (e) => {
    this.babylon = e;
    const { canvas, scene, engine } = e;

    engine.runRenderLoop(() => {
      if (scene) {
        scene.render();
      }
    });
  }

  onDropElement = async (event) => {
    event.preventDefault();
    if ( event.target.tagName === "CANVAS" ) {
      var modelId = event.dataTransfer.getData("text");
      const model = this.props.mainpage.items.find(t => t.id == modelId)
      model.side = 'left'
      try {
        await this.importModel(model)
      } catch (e) {
        console.log(e)
      }
    }
  }

  getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  }

  buildModePath = async (model) => {
    const fixture = this.state.customAllColor.FIXTURE;
    if (fixture && model.category === 'Taps & Mixer') {
      for (const color of this.props.mainpage.colors) {
        if (color.type === 'FIXTURE' && model.prefix.indexOf(color.path) > -1) {
          const modelWithFixture = `${model.prefix.replace(color.path, fixture.path)}${model.color_suffix ? '-' + model.color_suffix : ''}`;
          const isExist = await checkExistAsset(this.rootURL + modelWithFixture + '/' + modelWithFixture + '.gltf');
          if (isExist) {
            console.log('change fixture', modelWithFixture);
            return modelWithFixture;
          }
        }
      }

    }
    return `${model.prefix ? model.prefix : ''}${model.size_suffix ? '-' + model.size_suffix : ''}${model.color_suffix ? '-' + model.color_suffix : ''}`
  }

  importModel = async (model) => {
    if(this.state.listModels.filter(m => !m.isEndPanel).length > 5 && !model.isEndPanel) {
      $("#alertModal").modal("show")
      return
    }
    this.setState({loading: true})
    let newModel = {...model};
    let availableModels = this.availableModels;
    let { scene, canvas, engine, boxLeft, boxRight, boxMiddle, gizmoManager } = this.babylon
    let meshes = [], side = newModel.side;
    let rootPath = await this.buildModePath(newModel);
    if(!rootPath) {
      toast.error("File does not existed!")
      return
    }

    if ((newModel.id + "").split("-").length === 1) {
      newModel.id = newModel.id + "-" + Math.random().toString(36).substring(7);
    }
    this._loadingElement.style.visibility = "visible"
    const lastEndPanel = availableModels.find(m => m.name === 'End Panel' && m.side === side);
    if (availableModels.length > 1 && lastEndPanel) {
      await this.removeModel(lastEndPanel);
    }
    await BABYLON.SceneLoader.ImportMeshAsync(null, this.rootURL + rootPath + '/' , `${rootPath}.gltf`, scene).then(async function (result, particleSystems, skeletons) {
      let newMeshes = result.meshes;
      const tagName = `model-${newModel.id}-${this.getRandomInt(10)}`
      newModel.active = true;
      newModel.tagName = tagName;
      let cloneBox = null;

      var boundingBox = BABYLON.BoundingBoxGizmo.MakeNotPickableAndWrapInBoundingBox(newMeshes[0]);
      let {x,y,z} =  boundingBox.getBoundingInfo().boundingBox.extendSizeWorld;

      if(side === 'right') {
        cloneBox = boxRight.clone();

        if (this.turnRight) {
          cloneBox.position.x = - this.rightWidth + z/10;
          cloneBox.position.z = this.lastZRight + x/10 + this.rightZWidth;
          newModel.zWidth = x*2/10;
          newModel.xWidth = null;
          this.rightZWidth += x*2/10 - (this.rightZWidth ? 0 : currentRightBuffer*2);
        } else {
          cloneBox.position.x =  -x/10 - this.rightWidth;
          cloneBox.position.z = z/10;
          newModel.widthValue = this.rightWidth;
          newModel.xWidth = x*2/10;
          newModel.zWidth = null;
          this.rightWidth += x/10*2;
       //   this.rightZWidth = z/10;
          if (!this.isReseting) {
            scene.activeCamera.target.x -= x/10;
          }
        }
        newModel.index = ++this.rightCount;
        //this.lastZRight = z/10;
        if (newModel.isCorner) {
          this.lastZRight = 2*z/10; //this.lastZRight - hardcodeBuffer[newModel.name];
          //currentRightBuffer = hardcodeBuffer[newModel.name];
        }

      } else {
        cloneBox = boxLeft.clone();

        if (this.turnLeft) {
          cloneBox.position.x = this.leftWidth - z/10;
          cloneBox.position.z = this.lastZLeft + x/10 + this.leftZWidth;
          newModel.zWidth = x*2/10;
          newModel.xWidth = null;
          this.leftZWidth += x*2/10 - (this.leftZWidth ? 0 : currentLeftBuffer*2);
        } else {
          cloneBox.position.x = x / 10 + this.leftWidth;
          cloneBox.position.z = z/10;
          newModel.widthValue = this.leftWidth;
          newModel.xWidth = x*2/10;
          newModel.zWidth = null;
          this.leftWidth += x/10*2;
       //   this.leftZWidth = z/10;

          if (!this.isReseting) {
            scene.activeCamera.target.x += x/10;
          }
        }
        newModel.index = -(++this.leftCount);
        /////
        if (this.leftCount === 0) {
          this.lastZRight = z/10;
        }
       // this.lastZLeft = z/10;
        if (newModel.isCorner) {
          this.lastZLeft = 2*z/10; //this.lastZLeft*2 //- hardcodeBuffer[newModel.name];
          //currentLeftBuffer = hardcodeBuffer[newModel.name];
        }
      }
      if (newModel.isCorner) {
        cloneBox.position.z = cloneBox.position.z //- hardcodeBuffer[newModel.name];
      }
      cloneBox.position.y = y/10;
      cloneBox.setEnabled(true);
      cloneBox.scaling = new BABYLON.Vector3(x, y, z)
      cloneBox.scaling.scaleInPlace(0.01);
      newMeshes[0].scaling.scaleInPlace(0.1);
      newMeshes[0].setBoundingInfo(boxLeft.getBoundingInfo());
      newMeshes.forEach( (mesh, idx) => {
        mesh.setParent(cloneBox)
        mesh.position = new BABYLON.Vector3(0, 0, 0);
        let customColor = this.state.customAllColor;
        if (customColor || model.customColor) {
          if (model.customColor) {
            customColor = {...customColor, ...model.customColor};
          }
          for (let type in customColor) {
            let meshIdx = newModel.meshIndexes.find( m => m.type.toLowerCase() == customColor[type].type.toLowerCase());
            if (mesh.material && mesh.material.id.toUpperCase().indexOf(type) > -1) {
              mesh.material.albedoTexture = new BABYLON.Texture(this.rootURL + customColor[type].path);
            }
          }
        }
          BABYLON.Tags.AddTagsTo(mesh, tagName);
          meshes.push(mesh)
      });
      if ((side === 'left' && this.turnLeft) || (side === 'right' && this.turnRight)) {
        cloneBox.rotationQuaternion = this.createRotateQuaternion(newModel);
      }
      this.listBoxes.push(cloneBox);
      const newListModels = [...this.state.listModels, newModel].sort((a,b) => a.index > b.index ? 1 : ((b.index > a.index) ? -1 : 0));
      await new Promise(re => this.setState({
        listModels: newListModels
      }, () => re()));
      this.availableModels = newListModels;
      if (this.availableModels.length === 1) {
        //console.log('add end panel from import 1')
        await this.addEndPanel('left');
        await this.addEndPanel('right');
      } else {
        if (side !== 'middle' && !model.isEndPanel) {
          //console.log('add end panel from import !1')
          await this.addEndPanel(side);
        }
      }
      this._loadingElement.style.visibility = "hidden"
      this.setState({loading: false})
    }.bind(this));
  }

  addEndPanel = async (side) => {
    console.log('add end panel', side)
    //console.log(this.props.mainpage.items)
    let endPanel = this.props.mainpage.items.find(m => m.name === "End Panel"); ////// for demo only
    endPanel.side = side;
    endPanel.isEndPanel = true;
    //endPanel.endPanelOfModelId = newModel.id;
    //endPanel.endPanelOfIndex = newModel.index;
    const lastEndPanel = this.availableModels.find(m => m.name === 'End Panel' && m.side === side);
    //console.log('add end panel', lastEndPanel)
    if (!lastEndPanel) {
      await this.importModel(endPanel);
    }
  }

  createRotateQuaternion = (model) => {
    var axis;
    if (model.index > 0) {
      axis = new BABYLON.Vector3(0, Math.PI/2, 0);
    } else {
      axis = new BABYLON.Vector3(0, -Math.PI/2, 0);
    }
    //axis.normalize();
    var theta = Math.PI/2;
    return new BABYLON.Quaternion.RotationAxis(axis, theta);
  }

  resetAllModels = async () => {
    await new Promise(re => this.setState({listModels : [], loading: false}, re()));
    this.availableModels = [];
    let meshes = this.babylon.scene.meshes;
    this.leftCount = -1;
    this.rightCount = 0;
    this.leftWidth = this.rightWidth = this.leftZWidth = this.rightZWidth = 0;
    this.turnRight = this.turnLeft = false;
    this.babylon.scene.activeCamera.target.x = 0;
   // camera.target = new BABYLON.Vector3(0, 30, 0);
   // camera.alpha = Math.PI/2;
  //  camera.beta = Math.PI/2;
    await meshes.forEach(mesh => {
      if (mesh.parent) {
        mesh.parent.setEnabled(false);
      }
      for(let index=0; index < mesh.length; index++) {
        mesh[index].dispose()
      }
    })
  }

  moveModel = async (model, navigate) => {
    let currentIndex = {...model}.index;
    let newListModels = [...this.state.listModels];
    let startIndex = newListModels[0].index;
    this.isReseting = true;
    this.resetAllModels();
    let eIndex = newListModels.findIndex(m => m.index === currentIndex);

    let currentEl = {...newListModels.find(m => m.index === currentIndex)};
    let nextIndex = currentIndex;
    if (navigate === 'right') {
      nextIndex = currentIndex + 1;
    } else {
      nextIndex = currentIndex - 1;
    }
    let nextEl = {...newListModels.find(m => m.index === nextIndex)};
    newListModels[newListModels.findIndex(e => e.index === nextIndex)] = {...currentEl, isTurnLeft: nextEl.isTurnLeft, isTurnRight: nextEl.isTurnRight};
    newListModels[eIndex] = {...nextEl, isTurnLeft: currentEl.isTurnLeft, isTurnRight: currentEl.isTurnRight};

    newListModels.forEach((m, i) => m.index = startIndex++);
    this.loadModels(newListModels)
    this.isReseting = false;
  }

  removeModel = async (model, addEndPanelAfterRemove = false) => {
    if (!model.isEndPanel) {
      this.setState({loading: true})
      let newListModels = this.state.listModels.filter(m => !m.isEndPanel && m.index !== model.index);
      await this.resetAllModels();
      this.loadModels(newListModels);
      this.setState({loading: false})
      return;
    }

    this.setState({loading: true})
    let needRemoveModels = [], needAddBackModel = [], listModels = this.availableModels;
    // remove end panel first
    let side = model.side
    let endPanelModel = listModels.find(m => m.isEndPanel && m.side === side);
    if (!model.isEndPanel && endPanelModel) {
      //console.log('remove end panel', endPanelModel)
      await this.removeModel(endPanelModel);
    }
    if (!model.isEndPanel && listModels.filter(m => !m.isEndPanel).length === 1) {
      this.resetAllModels();
      return;
    }
    // if (model.index === 0) {
    //   if (!listModels.some(m => m.index > 0 && !m.isEndPanel) ) {
    //     const currentIdx = this.state.listModels.findIndex(m => m.index === model.index)
    //     let newListModels = [...this.state.listModels];
    //     newListModels.splice(currentIdx, 1)
    //     this.resetAllModels();
    //     this.loadModels(newListModels);
    //     return;
    //   }
    // }

    if (model.isEndPanel) {
      needRemoveModels = listModels.filter(m => m.id === model.id);
    } else {

      if (model.index > 0) {
        needRemoveModels = listModels.filter(m => m.index >= model.index && !m.isEndPanel);
      }
      if(model.index <= 0) {
        needRemoveModels = listModels.filter(m => m.index <= model.index && !m.isEndPanel);
      }

      let cornerModel = listModels.find(m => m.cornerOfModelId === model.id);
      if (cornerModel) {
        if (cornerModel.index > 0) {
          currentRightBuffer = 0;
        } else {
          currentLeftBuffer = 0;
        }
        needRemoveModels.push(cornerModel);
      }
    }
/*    let endPanelModel = listModels.find(m => m.endPanelOfModelId === model.id);
    if (endPanelModel) {
      console.log('endPanelModel',endPanelModel)
      needRemoveModels.push(endPanelModel);
    }*/
    const newList =  this.availableModels.filter(m =>
      needRemoveModels.map(rm => rm.id).indexOf(m.id) < 0 && needRemoveModels.map(rm => rm.id).indexOf(m.cornerOfModelId) < 0);
    await new Promise(re => this.setState({
      listModels: newList
    }, () => re()));
    this.availableModels = newList;
    needRemoveModels.forEach((nrModel, index) => {
      if (!model.isEndPanel && nrModel.index !== model.index && nrModel.cornerOfModelId !== model.id) {
        needAddBackModel.push(nrModel);
      } else {
        if (nrModel.isTurnRight) {
          this.turnRight = false;
        }
        if (nrModel.isTurnLeft) {
          this.turnLeft = false;
        }
        if (nrModel.side === 'left' || nrModel.side === 'middle' || !nrModel.side) {
          if (nrModel.xWidth) {
            this.leftWidth -= nrModel.xWidth;
            this.babylon.scene.activeCamera.target.x -= nrModel.xWidth/2;
          }
          if (nrModel.zWidth  && nrModel.index != 0) {
            this.leftZWidth -= nrModel.zWidth;
          }

          this.leftCount--;
        }
        if (nrModel.side === 'right') {
          if (nrModel.xWidth && nrModel.index != 0) {
            this.rightWidth -= nrModel.xWidth;
            this.babylon.scene.activeCamera.target.x += nrModel.xWidth/2;
          }
          if (nrModel.zWidth && nrModel.index != 0) {
            this.rightZWidth -= nrModel.zWidth;
          }
          this.rightCount--;
        }
      }
      let mesh = this.babylon.scene.getMeshesByTags(nrModel.tagName);
      if(mesh.length > 0) {
        if (mesh[0].parent) {
          mesh[0].parent.setEnabled(false);
        }
        for(var index=0; index < mesh.length; index++) {
          mesh[index].dispose()
        }
      }

      delete nrModel.tagName
      delete nrModel.active
    });
    for (const naModel of needAddBackModel) {
      if (model.index === 0) {
        naModel.active = true;
        if (naModel.side === 'right') {
          naModel.index-=1;
        } else {
          naModel.index+=1;
        }
      }

      await this.importModel(naModel);
    }
    console.log('removing model', model)
    console.log(this.availableModels)
    if (!model.isEndPanel && addEndPanelAfterRemove) {
      console.log('add end panel from remove')
      await this.addEndPanel(side);
    }
    this.setState({loading: false})
  }

  removeModelByTagName = (tagName) => {
    this.removeModel(this.state.listModels.find(m => m.tagName === tagName))
  }

  getTotalPrice = () => {
    return this.state.listModels.reduce((cur, next) => {
      cur += parseFloat(next.price) || 0
      return cur;
    }, 0)
  }

  processBirdEyeScreenshot = () => {
    let {leftWidth : x1, rightWidth: x2} = this;
    let z = this.leftZWidth > this.rightZWidth ? this.leftZWidth : this.rightZWidth;

    let vector = new BABYLON.Vector3( x1/2 - x2/2, 0, z/3)
    var camera =  new BABYLON.ArcRotateCamera("Camera", Math.PI / 2, 0 , 0, vector,  this.babylon.scene);
    camera.radius += (220 + 50* this.state.listModels.length + z);
    return new Promise((resolve, reject) => {
      BABYLON.Tools.CreateScreenshotUsingRenderTarget(this.babylon.engine, camera,
        {precision: 3, width: 800, height: 600}, async function(data) {
          // use the ipfs.add method to pin files
         // const result = await ipfs.add(dataURLtoFile(data, "birdEye"))
         // resolve('https://ipfs.infura.io/ipfs/' + result[0].hash);
          resolve(data);
        });
    })
  }

  processFrontScreenshot = () => {
    let {leftWidth : x1, rightWidth: x2} = this;
    let z = this.leftZWidth > this.rightZWidth ? this.leftZWidth : this.rightZWidth;

    let vector = new BABYLON.Vector3( x1/2 - x2/2, 50, z/3)
    var camera =  new BABYLON.ArcRotateCamera("Camera", Math.PI / 2, Math.PI / 2 , 0, vector, this.babylon.scene);
    camera.radius += (220 + x1/2 + x2/2);
    return new Promise((resolve, reject) => {
      BABYLON.Tools.CreateScreenshotUsingRenderTarget(this.babylon.engine, camera,
        {precision: 3, width: 800, height: 600}, async function(data) {
          // use the ipfs.add method to pin files
          //const result = await ipfs.add(dataURLtoFile(data, "front"))
         // resolve('https://ipfs.infura.io/ipfs/' + result[0].hash);
          resolve(data);
        });
    })
  }

  processIsoScreenshot = () => {
    let {leftWidth : x1, rightWidth: x2} = this;
    let z = this.leftZWidth > this.rightZWidth ? this.leftZWidth : this.rightZWidth;
    let {scene,engine} = this.babylon;
    let camera = scene.activeCamera;
    camera.radius += ( x1/2 + x2/2);
    return new Promise((resolve, reject) => {
      BABYLON.Tools.CreateScreenshotUsingRenderTarget(engine, camera,
        {precision: 3, width: 800, height: 600}, async function(data) {
          // use the ipfs.add method to pin files
        //  const result = await ipfs.add(dataURLtoFile(data, "front"))
         // resolve('https://ipfs.infura.io/ipfs/' + result[0].hash);
          resolve(data);
        });
    })
  }


  getQuote = async () => {
    $("#alertModal").remove()
    let birdeyesData = await this.processBirdEyeScreenshot();
    let frontData = await this.processFrontScreenshot();
    let isoData = await this.processIsoScreenshot();
    // Create a FormData and append the file with "image" as parameter name
    let birdeyes = await ImageUploadService.upload(birdeyesData)
    let front = await ImageUploadService.upload(frontData)
    let iso = await ImageUploadService.upload(isoData)
    let screenshots= {birdeyes: birdeyes.data, front: front.data, iso: iso.data};

    this.props.submitModels(this.state.listModels, screenshots, this.state.customAllColor);
    this.props.history.push("/getquote");
  }

  onSelectColor = async (model, color) => {

    //this.setState({listModels: newListModel});
    let newListModels = [...this.state.listModels];
    let needChangeModelIndex = newListModels.findIndex(m => m.id === model.id);
    let modelCustomColor = newListModels[needChangeModelIndex].customColor;
    if (!modelCustomColor) {
      modelCustomColor = {[color.type] : color};
    } else {
      modelCustomColor = {...modelCustomColor, [color.type] : color};
    }
    newListModels[needChangeModelIndex].customColor = modelCustomColor;
    let startIndex = newListModels[0].index;

    newListModels.forEach((m, i) => m.index = startIndex++);
    this.isReseting = true;
    this.resetAllModels();
    this.loadModels(newListModels);
    this.isReseting = false;

  }

  onSelectSize = async (model, size) => {
    try {
      let sizeProps = model.sizes.find(c => c.width == size);
      if (sizeProps) {
        //this.setState({listModels: newListModel});
        let newListModels = [...this.state.listModels];
        let needChangeModelIndex = newListModels.findIndex(m => m.id === model.id);
        newListModels[needChangeModelIndex].size_suffix = sizeProps.suffix;
        let startIndex = newListModels[0].index;

        newListModels.forEach((m, i) => m.index = startIndex++);
        this.isReseting = true;
        this.resetAllModels();
        this.loadModels(newListModels);
        this.isReseting = false;
      }
    } catch (e) {
      console.log(e)
    }
  }

  turnRotate = async (model, cornerModel) => {
    this.removeModel(model);
    //************
    //
    // test only , please REMOVE after adapt
    //console.log(this.props.mainpage.items)

    //
    //************

    let direction = model.side;
    if (direction === 'right') {
      cornerModel = this.props.mainpage.items.find(m => m.category === "Corner" && m.name === cornerModel + " Right"); ////// for demo only
      cornerModel.side = 'right';
      cornerModel.isCorner = true;
      cornerModel.cornerOfModelId = model.id;
      await this.importModel(cornerModel);
      this.turnRight = true;
      model.isTurnRight = true;
    }
    if (direction === 'left') {
      cornerModel = this.props.mainpage.items.find(m => m.category === "Corner" && m.name === cornerModel + " Left");////// for demo only
      cornerModel.side = 'left';
      cornerModel.isCorner = true;
      cornerModel.cornerOfModelId = model.id;
      await this.importModel(cornerModel);
      this.turnLeft = true;
      model.isTurnLeft = true;
    }
    await this.importModel(model);
  }

  changeAllModelColor = async (color) => {
    if ("CABINET" in color) {
      color["KICKER"] = color["CABINET"];
    }
    this.setState({customAllColor: {...this.state.customAllColor, ...color}}, () => {
      let newListModels = [...this.state.listModels];
      this.isReseting = true;
      this.resetAllModels();
      this.loadModels(newListModels);
      this.isReseting = false;
    });
  }

  _totalBoundingInfo = function(meshes){
    var boundingInfo = meshes[0].getBoundingInfo();
    var min = boundingInfo.minimum.add(meshes[0].position);
    var max = boundingInfo.maximum.add(meshes[0].position);
    for(var i=1; i<meshes.length; i++){
    //  if (meshes[i].parent) {
        boundingInfo = meshes[i].getBoundingInfo();
        min = BABYLON.Vector3.Minimize(min, boundingInfo.minimum.add(meshes[i].position));
        max = BABYLON.Vector3.Maximize(max, boundingInfo.maximum.add(meshes[i].position));
     // }
    }
    return new BABYLON.BoundingInfo(min, max);
  }

  isometric = () => {

    let {scene,engine} = this.babylon;
    if (this.listBoxes && this.listBoxes.length > 0) {
      let radius = this._totalBoundingInfo(this.listBoxes).boundingSphere.radiusWorld;
      let camera = scene.activeCamera;
      let aspectRatio = engine.getAspectRatio(camera);
      let halfMinFov = camera.fov / 2;
      if (aspectRatio < 1) {
        halfMinFov = Math.atan( aspectRatio * Math.tan(camera.fov / 2) );
      }
      let viewRadius = Math.abs( radius / Math.sin(halfMinFov) );
      let buffer =  this.listBoxes.length === 1 ? 190 : this.listBoxes.length === 2 ? 100 : -10 * this.listBoxes.length;
      camera.radius = viewRadius + buffer;
    }
  }

  loadModels = async (models) => {
    let listLeftModel = models.filter(m => m.index <= 0).sort((a,b) => a.index < b.index ? 1 : ((b.index < a.index) ? -1 : 0));
    for (let i = 0; i < listLeftModel.length; i++) {
      listLeftModel[i].side = 'left';
      if (listLeftModel[i].isTurnLeft) this.turnLeft = true;
      await this.importModel(listLeftModel[i]);
    }

    let listRightModel = models.filter(m => m.index > 0);
    for (let i = 0; i < listRightModel.length; i++) {
      listRightModel[i].side = 'right';
      if (listRightModel[i].isTurnRight) this.turnRight = true;
      await this.importModel(listRightModel[i]);
    }
  }

  render() {
    let {fetchItems, filter} = this.props;
    let w = 0, d = 0,  h = 0;
      this.state.listModels.forEach( model => {
        w += parseInt(model.width || 0)
        d = d < parseInt(model.depth || 0) ? parseInt(model.depth) : d
        h = h < parseInt(model.height || 0) ? parseInt(model.height) : h
    })

    let valSiteUnderConstruction = '0'
    let pathColorBenchtop = 'material/Benchtop/Polytec-Black.png';

    if (this.props.mainpage && this.props.mainpage.siteUnderConstruction && this.props.mainpage.siteUnderConstruction.valSiteUnderConstruction !== '0') {
      valSiteUnderConstruction = this.props.mainpage.siteUnderConstruction.valSiteUnderConstruction
    }

    if (this.props.mainpage && this.props.mainpage.siteUnderConstruction) {
      pathColorBenchtop = this.props.mainpage.siteUnderConstruction.pathColorBenchtop
    }

    this.state.customAllColor.BENCHTOP["path"] = pathColorBenchtop;

    if (valSiteUnderConstruction === '0') {
      return (
        <div className="main" id="sidebarContent">
          <Header onConfig={::this.openSetting}/>
          <div className="main-wrapper">
            <SideBar items={this.props.mainpage.items} colors={this.props.mainpage.colors} productMenus={this.props.mainpage.productMenus}
              loading={this.state.loading}
              changeAllModelColor={this.changeAllModelColor}
              selectedColors={this.state.customAllColor}
              listModels={this.state.listModels} onSelectItem={(item) => this.setState({ selectedItem: item })}
              onAddItem={async (item) => {
                this.setState({ loading: true })
                await this.importModel(item)
                this.setState({ loading: false })
              }} onRemoveItem={item => this.removeModel(item, true)} />
            <div className="main-content">
              {!this.state.setting ?
                (
                  <div>
                    <button type="button" id="sidebarCollapse" className="btn btn-info sr-only">
                      <i className="fas fa-align-left" />
                      <span>Toggle Sidebar</span>
                    </button>
                    <div className="main-babylon">
                      <BabylonScene onSceneMount={this.onSceneMount} onDropElement={this.onDropElement}
                        reset={this.resetAllModels} isometric={this.isometric}
                        listModels={this.state.listModels} removeModel={this.removeModelByTagName} />
                    </div>

                    <Configuration colors={this.props.mainpage.colors} turnRotate={this.turnRotate} onSelectColor={this.onSelectColor}
                      loading={this.state.loading}
                      isTurnRight={this.turnRight} isTurnLeft={this.turnLeft}
                      onSelectSize={this.onSelectSize} moveModel={this.moveModel}
                      listModels={this.state.listModels} selectedItem={this.state.selectedItem}
                      onRemoveItem={item => this.removeModel(item, true)} />
                    <div className="main-footer bg-white">

                      <div className="row">
                        <div className="col-md-6">
                          <div className="main-footer-price fontColorFooter">
                            {filter.shape ?
                              `Dimensions: W: ${w || '0'}mm D: ${d || '0'}mm H: ${h || '0'}mm` : ''
                            }
                          </div>
                        </div>
                        <div className="col-md">
                          <div className="main-footer-price fontColorFooter">
                            Total Price: <span className="color-green">${this.getTotalPrice()}</span>
                          </div>
                        </div>
                        <div className="col-md">
                          <div className="main-footer-button">
                            <a onClick={() => {
                              if (this._loadingElement) {
                                this._loadingElement.style.visibility = "visible"
                              }
                              return this.getQuote();
                            }}
                              className="btn btn-success">Send Estimate</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Setting onFinish={::this.closeSetting} onSubmitFilter={this.props.submitFilter}/>
              )
            }
            </div>
          </div>
          <ToastContainer />
          <div className="modal fade dapp-modal" id="alertModal" tabIndex="-1" role="dialog"
            aria-labelledby="rotationModalLabel" data-backdrop="static" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title">Maximum Module Limit Reached!</h3>
                </div>
                <div className="modal-body">
                  <div className="rotate-list">
                    <div className="row">
                      <div className="col-md-12">For a configuration of more than 6 modules please contact an AlfrescoPlus™ consultant to help build your dream outdoor kitchen on 1300 174 876. </div>
                    </div>
                  </div>
                  <div className="main-footer-bottom mt-3 rotate-action">
                    <button type="button" className="btn btn-success" data-dismiss="modal">OK</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal dapp-modal d-block d-sm-none" id="warningModal" tabIndex="-1" role="dialog"
            aria-labelledby="rotationModalLabel" data-backdrop="static" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="rotate-list">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <p>This app is the best used on large tablets and desktop computers in landscape mode. Please ensure you are using these devices/features for the best results.</p>
                        <p>This is <b>NOT</b> a mobile app.</p>
                      </div>
                    </div>
                  </div>
                  <div className="main-footer-bottom mt-3 rotate-action text-center">
                    <button type="button" className="btn btn-success" onClick={() => { $("#warningModal").remove() }}>OK</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <>
          <span dangerouslySetInnerHTML={template} />
        </>
      )  
    }
  }
}

function mapStateToProps(state) {
  const {filter, mainpage} = state;
  return {
    filter, mainpage
  };
}

export default connect(mapStateToProps, {fetchItems, submitFilter, submitModels, fetchColors, fetchProductMenus, fetchSiteUnderConstruction})(withRouter(MainPage));
