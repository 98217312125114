import axios from 'axios';
const UPLOAD_PATH = '/server/image-upload';

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

class ImageUploadService {
  constructor() {
  }

  async upload(dataurl) {
    var base64ImageContent = dataurl.replace(/^data:image\/(png|jpg);base64,/, "");
    var blob = b64toBlob(base64ImageContent, 'image/png');
    var formDataToUpload = new FormData();
    formDataToUpload.append("image", blob);
    return axios.post(UPLOAD_PATH, formDataToUpload, {
      headers: {
        'accept': 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${formDataToUpload._boundary}`,
      }
    });
  }

};

export default new ImageUploadService();